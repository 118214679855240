<template>
<v-container fill-height app>
<v-row justify="center" class="pt-1">
    <v-col align="center" :cols="cols_carrousel" class="pa-1 mt-0">
        <v-carousel v-model="carrousel"  height="900" interval="6000" lazy cycle>
            <v-carousel-item eager>
                <v-img src="/img/protection_home_01.png" eager width="500">
                        <v-row>
                             <v-col>
                                <v-card class="cardColor">
                                    <v-card-title class="justify-center" style="word-break: break-word" align="center">UNA AYUDA PARA TI<TItle></TItle></v-card-title>
                                    <v-card-text class="justify-center" color="grey darken-4"><strong>Tu código QR será un auxiliar para recibir ayuda oportuna y avisar a tus seres queridos.</strong></v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                </v-img>
            </v-carousel-item>
            <v-carousel-item eager>
                <v-img  src="/img/protection_home_02.png" eager width="500">
                        <v-row >
                            <v-col>
                                <v-card class="cardColor">
                                    <v-card-title class="justify-center" style="word-break: break-word" color="white">INFORMACIÓN MÉDICA</v-card-title>
                                    <v-card-text class="justify-center" ><strong>Tu código QR puede proporcionar información muy importante para que los servicios médicos te brinden la ayuda que necesitas.</strong></v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                </v-img>
            </v-carousel-item>
            <v-carousel-item eager>
                <v-img  src="/img/protection_home_03.png" eager width="500">
                        <v-row >
                            <v-col>
                                <v-card class="cardColor">
                                    <v-card-title class="justify-center" style="word-break: break-word" color="white">ALERTAS INMEDIATAS</v-card-title>
                                    <v-card-text class="justify-center"><strong>proteccion.prosperus avisará a tus contactos de emergencia proporcionándoles un número de contacto y tu ubicación en tiempo real para que puedan reunirse contigo.</strong></v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                </v-img>
            </v-carousel-item>
            <v-carousel-item eager>
                <v-img  src="/img/protection_home_04.png" eager width="500">
                        <v-row justify="center" align="center">
                            <v-col>
                                <v-card class="cardColor">
                                    <v-card-title class="justify-center" style="word-break: break-word" color="white"><strong>protection.prosperus.network</strong></v-card-title>
                                    <v-divider></v-divider>
                                <v-card-actions class="justify-center">
                                <v-btn
                                small
                                @click="login_signup()"
                                color="blue lighten-2"
                                >
                                    <span class="mr-0 pr-0">Crear cuenta</span> 
                            </v-btn>
                            </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>
                </v-img>
            </v-carousel-item>
        </v-carousel>
    </v-col>
    <v-col align="center" :cols="cols" class="pa-1 mt-0">
        <v-card height="1000">
            <v-card-title class=mainmessage>SABEMOS QUE LA LIBERTAD SE DISFRUTA MEJOR CON UN POCO DE PROTECCIÓN</v-card-title>
            <v-card-title class=subtitle>¿Qué es PROTECCION?</v-card-title>
            <v-card-text>Es un sistema para <strong>administrar tu información médica para emergencias</strong> haciéndola accesible por medio de la lectura de los <strong>códigos QR</strong> que te proporcionamos, con el fin de obtener una <strong>atención médica más adecuada y oportuna</strong> en caso de <strong>accidentes o emergencias médicas</strong>.<br> A través de nuestro sistema <strong>cualquier persona</strong> que lea el código QR podrá <strong>emitir una alerta</strong> a todos tus contactos de emergencia por medio de <strong>SMS y correo electrónico.</strong> La información de tus contactos es <strong>privada</strong> y nadie podrá verla más que tú. El sistema se encarga de enviar las alertas sin revelar los datos para proteger tu información y la de tus seres queridos.</v-card-text>
            <v-card-text>
                <v-list>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title class=subtitle>Comienza a usar protection.prosperus:</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title class=subtitle>01 - Crea una cuenta</v-list-item-title>
                            <v-list-item-subtitle class=subtitle>Sólo necesitas tu email y una contraseña.</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item thre-line>
                        <v-list-item-content>
                            <v-list-item-title class=subtitle>02 - Ingresa tus datos</v-list-item-title>
                            <v-list-item-subtitle class=subtitle>Tu información médica y contactos.</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title class=subtitle>03 - Elige una suscripción</v-list-item-title>
                            <v-list-item-subtitle class=subtitle>Recibe tu medalla con el código QR <br>y empieza a recibir alertas.</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-btn
                            small
                            @click="login_signup()"
                            color="blue lighten-2"
                            >
                                <span class="mr-0 pr-0">Crear cuenta</span> 
                            </v-btn>
                </v-list>
                <br>    
            </v-card-text>
        </v-card>
    </v-col>
</v-row>
 <!-- Forma dialog_signup -->
  <v-dialog v-model="dialog_signup" max-width="600">
    <v-card>
      <v-card-title class="blue lighten-2" dense>Crear una cuenta:</v-card-title>
    <v-row no-gutters justify="space-around">
     </v-row>
      <v-card-text>
        <v-text-field
        v-model="username"
        :error-messages="emailErrors"
        label="e-mail"
        type:
        required
        prepend-icon="mdi-account"
      ></v-text-field>
      <v-text-field 
        v-model="usernameConfirm"
        :error-messages="emailErrors"
        label="confirmar e-mail"
        required
        prepend-icon="mdi-account"
      ></v-text-field>
        <v-text-field
          v-model="password"
          prepend-icon="mdi-key"
          type="password"
          label="Elige una contraseña"
        ></v-text-field>
        <v-text-field
          v-model="passwordConfirm"
          prepend-icon="mdi-key"
          type="password"
          label="confirmar la contraseña"
        ></v-text-field>
      <h3 class="red--text text--lighten-1">{{errorUsername}}</h3>
      <h3 class="green--text">{{confirmationMessage}}</h3>
      </v-card-text>
      <v-card-text>{{loginErrorMessage}}</v-card-text>
      <v-card-actions >
        <v-spacer></v-spacer>
        <v-btn  color="red"  @click="cancelar_signup()">Cancelar</v-btn>
        <v-btn  color="green" :loading="signingup" @click="hacer_signup()">Crear cuenta...</v-btn>
      </v-card-actions>
       <v-card-actions>
      <v-spacer></v-spacer>
        <v-btn  color="green" :loading="signingup" @click="cancelar_signup()">Ok</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</v-container>
  
</template>

<script>
import axios from 'axios'
const axiosInstance = axios.create({
  headers: {
    "Access-Control-Allow-Origin": "*"
  }
});
export default {
    name: "Home",
    data: () => ({
        username:"",
    usernameConfirm:"",
    password:"",
    passwordConfirm:"",
    emailErrors:"",
    confirmationMessage:"",
        carrousel:0,
        dialog_signup:false,
        loginErrorMessage:"",
        logginin:false,
        errorUsername:"",
        signingup:false,

    }),
    methods:{
        cancelar_signup(){
    this.dialog_signup=false
  },
        login_signup(){
    this.confirmationMessage=""
    this.loginErrorMessage=""
    this.signupButtons=true
    this.confirmMessage=""
    this.dialog_signup=true
  },
  async hacer_signup(){
    if(this.username!=this.usernameConfirm){
      this.errorUsername="¡El e-mail no coincide!"
    }
    if(this.password!=this.passwordConfirm){
      this.errorUsername="¡El password no coincide!"
    }
    if(this.username==this.usernameConfirm & this.password==this.passwordConfirm){
      this.errorUsername="Creando cuenta..."
      this.signingup=true
      try {
        const response = await axiosInstance.post(this.apiUrl + "/api/users/signup", {
          username: this.username,
          password: this.password
        })
        if(response.data.status=="error"){
          this.errorUsername="Este correo ya está registrado"
          this.signingup=false
        }
        if(response.data.status=="ok"){
          this.signingup=false
          this.signupButtons=false
          this.errorUsername=""
          this.confirmationMessage="¡Tu cuenta se ha creado! \n Haz click en el correo que te acabamos de enviar para confirmar tu cuenta"
          this.loginErrorMessage=""
        } else if(response.data.status.message=="authentication failed"){
          this.loginErrorMessage="No se encontró el usuario. Revisa tus datos y vuelve a intentarlo"
          this.logginin=false
        }         
  } catch (error) {
    console.error(error);
  }
    }
  },
          async hacer_login(){
    this.logginin=true
    try {
    const response = await axiosInstance.post(this.apiUrl + "/api/users/login", {
        username: this.username,
        password: this.password
        })
        if(response.data.status=="ok"){
          localStorage.setItem('loggedin', true)
          localStorage.setItem('profile', JSON.stringify(response.data.userProfile.data))
          localStorage.setItem('secret', response.data.user.secret)
          localStorage.setItem('user', JSON.stringify(response.data.user))
          localStorage.setItem('profileRef', response.data.userProfile.ref["@ref"].id)
          this.logginin=false
          this.dialog_login=false
          this.backend= true
          this.home=false
          this.public_profile=false
          this.loginErrorMessage=""
        } else if(response.data.status.message=="authentication failed"){
          this.loginErrorMessage="No se encontró el usuario. Revisa tus datos y vuelve a intentarlo"
          this.logginin=false
        } else if(response.data.status.message=="inactivo"){
          this.loginErrorMessage="Tu cuenta está inactiva"
          this.logginin=false
        }       
  } catch (error) {
    console.error(error);
  }
    
  },
    },
    computed: {
        cols () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 12
          case 'sm': return 12
          case 'md': return 6
          case 'lg': return 6
          case 'xl': return 6
        
    }
    return 0
      },
      cols_carrousel () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 12
          case 'sm': return 12
          case 'md': return 6
          case 'lg': return 6
          case 'xl': return 6
        
    }
    return 0
      },
    },
    mounted() {
}

}
</script>

<style>
.cardColor {
   background-color: rgba(255, 255, 255, 0.65) !important;
   justify-self : center;
   align-self: center;
   margin-top: 20px;
   margin-left: 8px;
   margin-right: 8px;
 }
 .mainmessage{
     font-size: 16px;
     word-break: break-word;
        justify-content: center;

 }

 .subtitle{
     font-size: 15px;
     word-break: break-word;
     justify-content: center;
 }

</style>