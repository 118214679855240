<template>
<v-container app>
    <v-row no-gutters justify="space-around">
        <v-col align="center" cols="12" md="4">
            <iframe height="350" src="https://prosperus.network/onlinehelp/index.php/"></iframe>
        </v-col>
    </v-row>

    <v-row align="center">
      <h1>Preguntas frecuentes</h1>
        <v-expansion-panels>
            <v-expansion-panel expand focusable>
            <v-expansion-panel-header>¿Cómo puedo crear mi cuenta?

            </v-expansion-panel-header>
                <v-expansion-panel-content>
                    Una pregunta

                </v-expansion-panel-content>

            </v-expansion-panel>
        </v-expansion-panels>
    </v-row>
</v-container>
</template>

<script>
export default {

}
</script>

<style>

</style>
