<template>
    <v-container app>
        <v-row no-gutters justify="space-around">
        <v-col  cols="12" md="4">
        <!--Tarjeta del usuario -->
        <v-card v-if="$store.state.activeToken">
           <!-- <v-img :src="vehicleImage"  max-height="140" justify="center">-->
                <v-card-text>
                <v-row  pa-3>
                   <v-col cols="4">
                        <!--Avatar -->
                        <v-avatar size="100">
                            <v-img :src="profileImage"></v-img>
                        </v-avatar> 
                    </v-col>
                    <v-col cols="8" align="center"> 
                        <v-btn id="botonTop" small @click="open_alert_form()" color="red darken-3" class="ma-2 pa-5 " rounded>
                            <v-icon small>mdi-alert</v-icon>
                                 ¡Enviar una alerta
                                <br>
                                a contactos!
                        </v-btn>
                        <v-btn id="botonTop" small color="red darken-3" :href="'tel:911'" class="ma-2 pa-5 " rounded>
                            <v-icon small>mdi-phone</v-icon>
                                 SOS 911
                        </v-btn>
                    </v-col> 
                </v-row>
                <v-list class="pa-0">
                    <v-list-item>
                        <v-list-item-icon class="pa-0">
                        <v-icon color="indigo">mdi-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                        <v-list-item-title class="wrap-text">Nombre</v-list-item-title>
                        <v-list-item-subtitle class="wrap-text">{{publicProfile.name}} {{publicProfile.lastName}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>   
                        <v-list-item-icon>
                        <v-icon color="indigo">mdi-cake-variant</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                        <v-list-item-title class="wrap-text">Edad</v-list-item-title>
                        <v-list-item-subtitle class="wrap-text">{{publicProfile.age}} años    </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-icon>
                        <v-icon color="indigo">mdi-heart</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                        <v-list-item-title class="wrap-text">Tipo sanguíneo</v-list-item-title>
                        <v-list-item-subtitle class="wrap-text">{{publicProfile.bloodType}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                        <v-list-item-icon>
                        <v-icon color="indigo">mdi-medical-bag</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                        <v-list-item-title class="wrap-text">Condiciones médicas</v-list-item-title>
                        <v-list-item-subtitle class="wrap-text" v-for="(item, index) in publicProfile.medicalConditions" :key="index">{{index+1}}- {{item[0]}}
                        <v-list-item-subtitle class="wrap-text">{{item[1]}}</v-list-item-subtitle></v-list-item-subtitle>
                        <v-list-item-subtitle v-if="publicProfile.medicalConditions==0">El usuario no proporcionó alguna</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                        <v-list-item-icon>
                        <v-icon color="indigo">mdi-pill</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                        <v-list-item-title class="wrap-text">Medicamentos</v-list-item-title>
                        <v-list-item-subtitle class="wrap-text" v-for="(item, index) in publicProfile.medicines" :key="index">{{index+1}}- {{item[0]}}
                        <v-list-item-subtitle class="wrap-text ml-4">{{item[1]}}</v-list-item-subtitle></v-list-item-subtitle>
                        <v-list-item-subtitle v-if="publicProfile.medicines==0">El usuario no proporcionó alguno</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                        <v-list-item-icon>
                        <v-icon color="indigo">mdi-allergy</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                        <v-list-item-title class="wrap-text">Alergias</v-list-item-title>
                        <v-list-item-subtitle class="wrap-text" v-for="(item, index) in publicProfile.alergies" :key="index">{{index+1}}- {{item[0]}}
                        <v-list-item-subtitle class="wrap-text ml-4">{{item[1]}}</v-list-item-subtitle></v-list-item-subtitle>
                        <v-list-item-subtitle v-if="publicProfile.alergies==0">El usuario no proporcionó alguna</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                        <v-list-item-icon>
                        <v-icon color="indigo">mdi-hospital-box-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                        <v-list-item-title class="wrap-text">Enfermedades o cirugías</v-list-item-title>
                        <v-list-item-subtitle class="wrap-text" v-for="(item, index) in publicProfile.diseasesSurgeries" :key="index">{{index+1}}- {{item[0]}}
                        <v-list-item-subtitle class="wrap-text ml-4">{{item[1]}}</v-list-item-subtitle>
                        <v-list-item-subtitle class="wrap-text ml-4">{{item[2]}}</v-list-item-subtitle>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="publicProfile.diseasesSurgeries==0">El usuario no proporcionó alguna</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                        <v-list-item-icon>
                        <v-icon color="indigo">mdi-account-supervisor-circle</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="wrap-text">Contatos de emergencia</v-list-item-title>
                            <v-list-item-subtitle class="wrap-text"> {{publicProfile.emergencyContacts}} Contacto(s)</v-list-item-subtitle>
                            <v-list-item-subtitle v-if="publicProfile.emergencyContacts==0">El usuario no proporcionó alguna</v-list-item-subtitle>
                            <v-list-item-action>
                            <v-row  pa-3>
                   
                    <v-col cols="12" align="center"> 
                        <v-btn id="botonTop" small @click="open_alert_form()" color="red darken-3" class="ma-2 pa-5 " rounded>
                            <v-icon small>mdi-alert</v-icon>
                                 ¡Enviar una alerta
                                <br>
                                a contactos!
                        </v-btn>
                        <v-btn id="botonTop" small color="red darken-3" :href="'tel:911'">
                            <v-icon small>mdi-phone</v-icon>
                                 SOS 911
                        </v-btn>
                    </v-col> 
                </v-row>
                            </v-list-item-action>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card-text>
        </v-card>
        <v-card v-if="$store.state.activeToken == false" class="ma-14 pa-6">
        <v-card-text class="text-center">
        <v-icon  x-large class="pa-4 justify-center">mdi-qrcode</v-icon>
        </v-card-text>
        <v-card-text align="center" >{{qrstatus}}</v-card-text>
        </v-card>
        </v-col>
        </v-row>
        <!-- Forma para enviar alertas -->
        <v-dialog v-model="dialog_alert" max-width="600">
            <v-card>
                <v-card-title align="center" style="word-break: break-word">Envia una alerta a los contactos de la GUARDA QR</v-card-title>
                <v-card-text>

                Dinos qué pasó y déjanos tus datos para que se comunique contigo alguno de los contactos de emergencia del usuario:
                <v-text-field v-model="goodSamaritanName" :error-messages="emailErrors" label="Tu nombre" required prepend-icon="mdi-account"></v-text-field>
                <v-text-field v-model="goodSamaritanPhone" :error-messages="emailErrors" label="Tu teléfono" required prepend-icon="mdi-phone"></v-text-field>
                <v-text-field v-model="goodSamaritanMessage" :error-messages="emailErrors" label="Cuéntanos qué pasó" required prepend-icon="mdi-message"></v-text-field>

                ¡Gracias por tu ayuda, es muy valiosa! Se enviará una alerta a los contactos y se comunicarán contigo para atender la emergencia.
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="red" @click="cancel_alert()">No enviar</v-btn>
                <v-btn text color="green" @click="send_alert()" :loading=sending>Enviar alerta</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
         <v-overlay :value="overlay" :opacity=.8 class="pa-10">¡Espera! Estamos obteniendo la geolocalización para compartirla con los contactos de emergencia, por favor acepta el permiso en tu navegador.</v-overlay>

    </v-container>
</template>

<script>
import axios from 'axios'
export default {
    name:"PublicProfile",
    data: () => ({
        mobile: '8001234567',
        gps_alert:false,
        dialog_alert:false,
        activeToken:false,
        goodSamaritanGPS:"",
        goodSamaritanName:"",
        goodSamaritanMessage:"",
        goodSamaritanPhone:"",
        qrtoken:"",
        qrstatus:"",
        publicProfile:{},
        apiurl:process.env.VUE_APP_ROOT_API,
        gettingLocation:"false",
        emailErrors:"",
        city:"",
        region:"",
        country:"",
        errorMessage:"",
        overlay:false,
        gpsDenied:false,
        sending:false,
    }),
    //mounted(){
      //  window.history.replaceState(null, document.title, "/public_profile.html")
    //},
    created(){
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        const qr = urlParams.get('qr')
        this.qrtoken=qr
        this.getPublicProfile(qr)

    },
    
    computed: {
    profileImage() {
      if (this.publicProfile.userPic != "") {
        return "/img/" +
          this.publicProfile.userPic
      } else {
        return "/img/user_icon.png"
      }
    },
    vehicleImage() {
      if (this.publicProfile.foto_de_vehiculo != "") {
        return "/img/" + this.publicProfile.foto_de_vehiculo
      } else {
        return "/img/profile_photo.png"
      }
    },
  },
    methods:{
        async getgps(){
            this.overlay=true
            try{await this.$getLocation()
            .then(coordinates => {
                this.goodSamaritanGPS=coordinates.lat+','+coordinates.lng
                this.dialog_alert=true
                this.overlay=false
            });
            }catch(e){
                this.gpsDenied=true
                alert(e)
                this.dialog_alert=true
                this.overlay=false
            }
        },
        async open_alert_form(){
            await this.getgps() 
        },
        cancel_alert(){
            this.dialog_alert=false
        },
        async send_alert(){
            this.sending=true
            try {
                let response = await axios.post(this.apiurl + "/api/perfiles/aviso", {
                    qrId:this.qrtoken,
                    gpsDenied:this.gpsDenied,
                    goodSamaritanGPS:this.goodSamaritanGPS,
                    goodSamaritanName:this.goodSamaritanName,
                    goodSamaritanMessage:this.goodSamaritanMessage,
                    goodSamaritanPhone:this.goodSamaritanPhone,
                    goodSamaritanDate:new Date().toLocaleString()
                    })
                if(response.data.status=="ok"){
                    this.sending=false
                    this.dialog_alert=false
                }
            }catch(error){
                console.log(error)
            }
        },
        async getPublicProfile(qr){
        try {
        const response = await axios.get(this.apiurl + "/api/perfiles?qr=" + qr)
            if(response.data.status=="active"){
                this.$store.state.activeToken=true
                this.publicProfile=response.data.profile
            } else if(response.data.status=="inactive"){
                this.$store.state.activeToken=false
                this.qrstatus="El QR ha sido deshabilitado por el usuario"
            } else if(response.data.status=="invalid token"){
                this.$store.state.activeToken=false
                this.qrstatus="El QR no es válido"
            }else if(response.data.status=="inexistente"){
                this.$store.state.activeToken=false
                this.qrstatus="El QR no exsite"
            }else if(response.data.status=="account_suspended"){
                this.$store.state.activeToken=false
                this.qrstatus="La cuenta no está habilitada"
            }
                
    } catch (error) {
        console.error(error);
    }
        
        },
    },
    components: {
    }

}
</script>

<style>
#botonTop {
    color:white;
}


</style>