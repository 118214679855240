<template>
<div>
    <v-col class="text-right">
        <v-btn x-small :loading="waiting_stripe" color="green" @click="open_form()">Comprar ahora
        </v-btn>
    </v-col>
    <!-- Forma suscribirme -->
    <v-dialog v-model="form" persistent :overlay="false" max-width="500px" transition="dialog-transition">
        <v-card class="mx-auto">
            <v-card-text align="center" class="pt-4">
                <v-img alt="Prosperus Logo" class="shrink mr-3 mt-0" contain src="https://prosperus.network/logo.png" transition="scale-transition" width="80" />
            </v-card-text>
            <v-card-text>
                <h2 class="pa-2"> Elige un modelo:</h2>
            </v-card-text>
            <v-card-text>
                <v-card elevation="4" shaped v-for="product in products" :key="product.id" class="mb-4" @click="select_product(product)">
                    <table>
                        <tr>
                            <td>
                                <v-img :src=product.picture width="90" class="ma-2">

                                </v-img>
                            </td>
                            <td>
                                Material: {{product.material}}<br>
                                Tamaño: {{product.size}}<br>
                                Color: {{ product.color }}<br>
                                <v-icon v-if="selected_product.id==product.id" color="green">mdi-check-circle</v-icon>
                                <v-icon v-if="selected_product.id!=product.id" color="grey">mdi-circle</v-icon>
                            </td>

                        </tr>
                    </table>
                </v-card>

            </v-card-text>
            <v-card-text v-if="product_selected">
                <h2 class="pa-2"> Elige un plan:</h2>
            </v-card-text>
            <v-card-text v-if="product_selected">
                <v-card elevation="4" shaped  v-for="(item, index) in precios" :key="index" class="mb-4" @click="select_plan(item)">
                    <table>
                        <tr>
                            <td>
                                <v-img src="https://prosperus.network/logo.png" width="90" class="ma-2">

                                </v-img>
                            </td>
                            <td>
                                {{item.nickname}}<br>
                                Costo: {{item.unit_amount/100 | toCurrency}}{{ item.currency }} /<span v-if="item.recurring.interval=='year'">Año</span><span v-if="item.recurring.interval=='month'">Mes</span> <br>
                                <v-icon v-if="selected_price.id==item.id" color="green">mdi-check-circle</v-icon>
                                <v-icon v-if="selected_price.id!=item.id" color="grey">mdi-circle</v-icon>
                            </td>

                        </tr>
                    </table>
                </v-card>

            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn  color="red" @click="cancelar_suscribirme()">Cancelar</v-btn>
                <v-btn :disabled="pagar"  color="green" :loading="waiting_stripe" @click="hacer_pago()">Pagar...</v-btn>
            </v-card-actions>

        </v-card>

    </v-dialog>
    <!--Cuenta no está activada-->
    <v-overlay class="pa-3" align="center" opacity=.95 justify="center" :value="completeData">
        Antes de suscribirte completa tus <br>
        <v-icon class="pr-1 pb-1" color="white">mdi-account-box</v-icon>Datos personales<br> con la dirección de envío de tu código QR <br><br><br>
        <v-btn color=green @click="completeData=false">Ok</v-btn>
    </v-overlay>
</div>
</template>

<script>
import axios from 'axios'
export default {
    name: "Stripe",
    props: ['price_id'],
    components: {},
    data: () => ({
        products: [{
                id: 1,
                name: "Guarda INOX",
                size: "24x35mm",
                material: "Acero inoxidable",
                color: "Acero",
                picture: "/img/inox_tag.png",
                selected: false
            },
            {
                id: 4,
                name: "Guarda Aluminio",
                size: "25x35mm",
                material: "Aluminio anodizado",
                color: "Negro",
                picture: "/img/black_aluminum.png",
                selected: false
            },
            {
                id: 2,
                name: "Pulsera INOX",
                size: "24x20mm",
                material: "Acero inoxidable",
                color: "Acero",
                picture: "/img/inox_bracelet.png",
                selected: false
            },
            {
                id: 3,
                name: "Guarda Dije",
                size: "30x30mm",
                material: "Acero inoxidable",
                color: "Negro",
                picture: "/img/round_black.png",
                selected: false
            }
        ],
        selected_price:{},
        prices: [{
                id: 1,
                name: "Plan mensual",
                amount: 40,
                period: "Mes",
                selected: false
            },
            {
                id: 2,
                name: "Plan anual",
                amount: 440,
                period: "Año",
                selected: false
            }
        ],
        completeData: false,
        precios: [],
        waiting_stripe: false,
        userProfile: {},
        form: false,
        planes: 0,
        apiurl: process.env.VUE_APP_ROOT_API,
        loading: false,
        product_selected:false,
        price_selected:false,
        customerEmail: "",
        selected_product:{},
        pagar:true
    }),
    methods: {
        select_product(product) {
            this.selected_product=product
            this.product_selected=true
        },
        select_plan(plan) {
            this.selected_price=plan
            this.pagar=false
        },
        cancelar_suscribirme() {
            this.form = false
            this.waiting_stripe = false
        },
        async hacer_pago() {
            this.waiting_stripe = true
            try {
                const response = await axios.post(this.apiurl + "/api/pay/create-checkout-session", {
                    customerEmail: this.userProfile.email,
                    client_reference_id: localStorage.getItem('user'),
                    price: this.selected_price.id,
                    profileRef: this.profileRef
                })
                if (response) {
                    this.waiting_stripe = false
                    window.location = response.data.url
                }
            } catch (err) {
                console.log(err)
            }
        },
        //enviar datos al backend
        async open_form() {
            if (this.userProfile.name == "" || this.userProfile.address.street == "" || this.userProfile.address.streetNumber == "" || this.userProfile.address.neighborhood == "" || this.userProfile.address.zipcode == "" || this.userProfile.address.alcaldiaMunicipio == "") {
                this.completeData = true
            } else {
                this.customerEmail = this.userProfile.email
                this.waiting_stripe = true
                try {
                    const response = await axios.post(this.apiurl + "/api/pay/get-prices", )
                    if (response) {
                        this.waiting_stripe = false
                        this.precios = response.data
                    }
                } catch (err) {
                    console.log(err)
                }
                this.form = true
                this.waiting_stripe = false
            }
        }
    },
    computed: {
    },
    created() {
        this.profileRef = localStorage.getItem('profileRef')
        this.userProfile = JSON.parse(localStorage.getItem('profile'))
    }
}
</script>

<style scoped>
.title {
    color: "success";
    font-size: 10px;
}
</style>
