import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import Vuex from 'vuex'
import VueGeolocation from 'vue-browser-geolocation';
import ImageUploader from 'vue-image-upload-resize'

Vue.use(ImageUploader);

Vue.use(VueGeolocation);

Vue.use(Vuex)
Vue.config.productionTip = false

const store = new Vuex.Store({
    state: {
        profile: {},
        secret: "",
        activeToken: false
    },
    mutations: {}
})

// Global filters
Vue.filter('toCurrency', function(value) {
    if (typeof value !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN'
    });
    return formatter.format(value);
});

Vue.filter('tsToDate', function(value) {
    if (!value) return ''
    var options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    var todate = new Date(value);
    var format = todate.toLocaleDateString("es-MX", options)
    return format
});

new Vue({
    vuetify,
    store: store,
    render: h => h(App)
}).$mount('#app')