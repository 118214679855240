<template>
<v-app app>
    <!-- Menú superior -->
    <v-app-bar clipped-left elevation="3" app dense  color="grey lighten-3">
        <div class="d-flex align-center">
            <v-img alt="Prosperus Logo" class="shrink mr-3 mt-0" contain src="https://prosperus.network/logo.png" transition="scale-transition" width="20" />
        </div>
        <span  class="texto_menu">PROTECCION</span>
        <v-spacer></v-spacer>
        <Stripe v-if="backend" />
        <v-btn v-if="backend" x-small @click="confirmar_logout()" color="grey">
            <v-icon small>mdi-logout</v-icon>
        </v-btn>
        
        <v-btn  v-if="home || public_profile" small text @click="login_signup()" color="blue lighten-2">
            <span class="mr-0 pr-0">Crear cuenta</span>

        </v-btn>
        <v-btn v-if="home || public_profile" small @click="login_dialog()" color="green darken-2">

            <span class="mr-2">Login</span>
            <v-icon small>mdi-login</v-icon>
        </v-btn>
    </v-app-bar>
    <!-- Ventana principal -->
    <v-main app style="background: #E7E7E7;">
        <PublicProfile v-if="public_profile" />
        <Backend v-if="menu_inferior==0 && backend==true" />
        <QrLogs v-if="menu_inferior==1" />
        <Help v-if="menu_inferior==2" />
        <Account v-if="menu_inferior==3" />
        <Home v-if="home" />
    </v-main>
    <!-- Menú inferior -->
    <v-bottom-navigation app v-if="backend">
        <v-btn-toggle mandatory tile color="green darken-3" group grow v-model="menu_inferior">
            <v-btn>
                <span>Perfil</span>
                <v-icon>mdi-account</v-icon>
            </v-btn>
            <v-btn>
                <span>QR logs</span>
                <v-icon>mdi-history</v-icon>
            </v-btn>
            <v-btn>

                <span>Ayuda</span>
                <v-icon>mdi-chat-question</v-icon>
            </v-btn>
            <v-btn>
                <span>Mi cuenta</span>
                <v-icon>mdi-account-details</v-icon>
            </v-btn>
        </v-btn-toggle>
        <v-dialog eager v-model="editBoardDialog">
            <v-card width="450">
                <iframe id="ifrm" src="https://prosperus.network/onlinehelp/index.php/',wheight:450,wwidth:350,pheight:520,pwidth:500,domain:'proteccion.prosperus.network',leaveamessage:true,check_messages:false,lang:'esp/'" width="340" height="450"></iframe>
            </v-card>
        </v-dialog>
    </v-bottom-navigation>
    <v-bottom-navigation app v-if="home">
        <v-btn-toggle mandatory tile color="green darken-3" group grow v-model="menu_inferior">
            <v-btn value="alertas" @click="editBoardDialog=true">
                <span>Ayuda</span>
                <v-icon>mdi-chat-question</v-icon>
            </v-btn>
        </v-btn-toggle>
        <v-dialog eager v-model="editBoardDialog">
            <v-card width="100%">
                <v-row align="center" justify="center" class="pa-6">
                    <div class="title">
                        <h4>Ayuda online</h4>
                    </div>
                    <v-col align="center" cols="12" md="4">
                        <iframe width="100%" height="320" src="https://prosperus.network/onlinehelp/index.php/"></iframe>
                    </v-col>
                </v-row>
                <v-row align="center" justify="center" class="pa-6">
                    <div class="title">
                    <h4>Preguntas frecuentes</h4>
                    <v-expansion-panels >
                        <v-expansion-panel expand focusable>
                            <v-expansion-panel-header>
                                ¿Cómo puedo crear mi cuenta?
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p class="respuesta">Abrir tu cuenta en PROTECCION no tiene costo.</p>
                                <p class="respuesta_tab">1.- Haz click en CREAR CUENTA en la parte superior de esta página.</p>
                                <p class="respuesta_tab">2.- Llena el formulario con tus datos.</p>
                                <p class="respuesta_tab">3.- Checa tu correo, te enviaremos un mail de confirmación.</p>
                                <p class="respuesta_tab">4.- En el correo de confirmación haz click en CONFIRMAR... para activar tu cuenta.</p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel expand focusable>
                        <v-expansion-panel-header>
                            ¿Cuánto cuesta PROTECCION?
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p class="respuesta">Abrir tu cuenta es gratis, puedes explorar nuestra plataforma y ver si te parece útil en tu día a día.</p>
                            <p class="respuesta">Para adquirir tu GUARDA QR y activar los servicios de alertas por correo, SMS y geolocalización, te ofrecemos dos planes:</p>
                            <p class="respuesta_tab">A) Plan mensual. $MXN 45.00. Cancela cuando quieras</p>
                            <p class="respuesta_tab">B) Plan anual. $MXN 495.00. 1 mes gratis. Plazo de un año</p>
                            <p class="respuesta">Adquiere el plan que mejor se adapte a tus necesidades y recibe en tu domicilio tu GUARDA QR en unos días.</p>
                        </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel expand focusable>
                            <v-expansion-panel-header>
                                ¿Perdí mi GUARDA QR, qué hago?
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p class="respuesta">Te recomendamos desactivarla en lo que la encuentras o pides una reposición:</p>
                                <p class="respuesta_tab">1.- Ingresa a tu cuenta con el botón de LOGIN en la parte superior de esta página.</p>
                                <p class="respuesta_tab">2.- Accede a la sección GUARDA QR y se mostrarán tus placas</p>
                                <p class="respuesta_tab">3.- Pulsa botón de editar: <v-icon small class="pl-2">mdi-pencil</v-icon></p>
                                <p class="respuesta_tab">4.- Pulsa el interrupto de Activo para apagarlo y quede Inactivo, en color gris.</p>
                                <p class="respuesta">Si la encuentras tu GUARDA QR puedes reactivarla cuando quieras y si pides la reposición aparecerá tu nueva GUARDA QR en cuanto sea producida por nuestro equipo.</p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel expand focusable>
                            <v-expansion-panel-header>
                                ¿Cómo están protegidos mis datos personales?
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p class="respuesta">Protección utiliza medidas de seguridad avanzadas. Además, tu información está encriptada y solo puede ser accedida por medio de tu cuenta personal, manten tu contraseña segura y evita compartirla. Tu perfil público se mostrará a quien lea tu GUARDA QR:</p>
                                <p class="respuesta_tab">1.- Nadie puede acceder a tu información privada ni la de tus contactos de emergencia. Sólo el sistema de PROTECCION la usa para enviar los mensajes de alerta.</p>
                                <p class="respuesta_tab">2.-Tu perfil público sólo muestra información de valor para atender emergencias, no incluye tu apellido, fecha de nacimiento ni cualquier otro dato privado.</p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </div>
                </v-row>
            </v-card>
        </v-dialog>
    </v-bottom-navigation>
    <!-- Forma confirmar_logut -->
    <v-dialog v-model="dialog_logout" max-width="600">
        <v-card>
            <v-card-title align="center" style="word-break: break-word">¿Quiere salir de prosperus.network?</v-card-title>
            <v-card-text>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="red" @click="cancelar_logout()">Cancelar</v-btn>
                <v-btn text color="green" @click="hacer_logout()">Salir</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- Forma dialog_login -->
    <v-dialog v-model="dialog_login" max-width="600">
        <v-card>
            <v-toolbar color="green" dark flat>
                <v-toolbar-title>Login:</v-toolbar-title>
            </v-toolbar>
            <v-row no-gutters justify="space-around">
            </v-row>
            <v-card-text>
                <v-text-field v-model="username" :error-messages="emailErrors" label="E-mail" type="email" required prepend-icon="mdi-account"></v-text-field>
                <v-text-field v-model="password" prepend-icon="mdi-key" type="password" label="password"></v-text-field>
            </v-card-text>
            <v-card-text>{{loginErrorMessage}}</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="red" @click="cancelar_login()">Cancelar</v-btn>
                <v-btn text color="green" :loading="logginin" @click="hacer_login()">Login</v-btn>
            </v-card-actions>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="grey" x-small @click="login_signup()">Quiero crear una cuenta</v-btn>
            </v-card-actions>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="grey" x-small @click="recover_password_dialog()">¡Olvidé mi contraseña!</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- Forma recover_password -->
    <v-dialog v-model="dialog_recover_password" max-width="600">
        <v-card>
            <v-toolbar color="black" dark flat>
                <v-toolbar-title>Recuperar contraseña:</v-toolbar-title>
            </v-toolbar>
            <v-row no-gutters justify="space-around">
            </v-row>
            <v-card-text>
                <v-text-field v-model="username" :error-messages="emailErrors" label="E-mail" type="email" required prepend-icon="mdi-account"></v-text-field>
            </v-card-text>
            <v-card-text>Ingresa el correo que registraste en tu cuenta y enviaremos un mail con tu contraseña...</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="red" @click="cancelar_login()">Cancelar</v-btn>
                <v-btn text color="green" :loading="logginin" @click="recover_password()">Recuperar...</v-btn>
            </v-card-actions>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="grey" x-small @click="signup()">Quiero crear una cuenta</v-btn>
            </v-card-actions>
            <v-card-actions>

            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- Forma mail_sent -->
    <v-dialog v-model="mail_sent_dialog" max-width="600">
        <v-card>
            <v-toolbar color="black" dark flat>
                <v-toolbar-title>Recuperar contraseña:</v-toolbar-title>
            </v-toolbar>
            <v-row no-gutters justify="space-around">
            </v-row>
            <v-card-text>
                <v-text-field v-model="username" :error-messages="emailErrors" label="E-mail" type="email" required prepend-icon="mdi-account"></v-text-field>
            </v-card-text>
            <v-card-text>Ingresa el correo que registraste en tu cuenta y enviaremos un mail con tu contraseña...</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="red" @click="cancelar_login()">Cancelar</v-btn>
                <v-btn text color="green" :loading="logginin" @click="recover_password()">Recuperar...</v-btn>
            </v-card-actions>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="grey" x-small @click="signup()">Quiero crear una cuenta</v-btn>
            </v-card-actions>
            <v-card-actions>

            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- Forma dialog_signup -->
    <v-dialog v-model="dialog_signup" max-width="600">
        <v-card>
            <v-card-title class="blue lighten-2" dense>Crear una cuenta:</v-card-title>
            <v-row no-gutters justify="space-around">
            </v-row>
            <v-card-text>
                <v-text-field v-model="username" :error-messages="emailErrors" label="e-mail" type="email" required prepend-icon="mdi-account"></v-text-field>
                <v-text-field v-model="usernameConfirm" :error-messages="emailErrors" label="confirmar e-mail" required prepend-icon="mdi-account"></v-text-field>
                <v-text-field v-model="password" prepend-icon="mdi-key" type="password" label="Elige una contraseña"></v-text-field>
                <v-text-field v-model="passwordConfirm" prepend-icon="mdi-key" type="password" label="confirmar la contraseña"></v-text-field>
                <h3 class="red--text text--lighten-1">{{errorUsername}}</h3>
                <h3 class="green--text">{{confirmationMessage}}</h3>
            </v-card-text>
            <v-card-text>{{loginErrorMessage}}</v-card-text>
            <v-card-actions v-if="signupButtons">
                <v-spacer></v-spacer>
                <v-btn text color="red" @click="cancelar_signup()">Cancelar</v-btn>
                <v-btn text color="green" :loading="signingup" @click="hacer_signup()">Crear cuenta...</v-btn>
            </v-card-actions>
            <v-card-actions v-if="!signupButtons">
                <v-spacer></v-spacer>
                <v-btn color="green" :loading="signingup" @click="cancelar_signup()">Ok</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- Confirmaciòn para activación de cuenta -->
    <v-overlay align="center" justify="center" :value="overlay" opacity=".85" color='#000000'>
        ¡Hola! <br>
        Te damos la bienvenida a PROTECCION.<br><br><br>
        {{confirmMessage}}
        <br>
        <br>
        <v-btn v-if="!confirmed" color="success" @click=confirm_account()>
            Activar mi cuenta...
        </v-btn>
        <v-btn v-if="confirmed" color="success" @click=login_dialog()>
            Login...
        </v-btn>
        <br><br>
        <v-btn v-if="!confirmed" color="red" @click=cancel_account()>
            Ahora no...
        </v-btn>
    </v-overlay>
    <!--Cuenta no está activada-->
    <v-overlay class="pa-3" align="center" opacity=.95 justify="center" :value="accountPending">
        ¡Hola! Por favor confirma tu cuenta: abre a tu correo y busca "Tu cuenta en PROTECCION está lista..." y haz click en el botón de "Confirmar..."<br><br><br>
        <v-btn color=green @click="cancelar_login()">Ok</v-btn>
    </v-overlay>
    <!--Gracias por aceptar-->
    <v-overlay dark class="pa-3" align="center" opacity=.95 justify="center" :value="overlayContact">
        ¡Hola! Gracias por aceptar la invitación para ser contacto de emergencias. A partir de ahora recibirás alertas por medio de SMS y correo electrónico.<br> Puedes crear tu propia cuenta, haz click en el botón "Crear cuenta"<br><br>
        <v-btn color=green @click="overlayContact=false">Ok</v-btn>
    </v-overlay>
</v-app>
</template>

<script>
import axios from 'axios'
import PublicProfile from "./components/PublicProfile.vue"
import Account from "./components/Account.vue"
import Stripe from "./components/Stripe.vue"
import Help from "./components/Help.vue"
import QrLogs from "./components/QrLogs.vue"
import Backend from "./components/Backend"
import Home from "./components/Home"
export default {
    name: 'App',
    components: {
        Stripe,
        Account,
        QrLogs,
        Backend,
        Help,
        Home,
        PublicProfile,
    },
    data: () => ({
        editBoardDialog: false,
        dialog_recover_password: false,
        confirmed: false,
        mail_sent_dialog: false,
        confirmMessage: "",
        absolute: true,
        overlay: false,
        errorUsername: "",
        confirmationCode: "",
        loginErrorMessage: "",
        apiurl: process.env.VUE_APP_ROOT_API,
        apiUrl: process.env.VUE_APP_ROOT_API,
        logginin: false,
        menu_inferior: 0,
        loggedin: false,
        home: false,
        signingup: false,
        backend: false,
        dialog_logout: false,
        dialog_login: false,
        dialog_signup: false,
        public_profile: false,
        username: "",
        usernameConfirm: "",
        password: "",
        passwordConfirm: "",
        emailErrors: "",
        confirmationMessage: "",
        inviteConfirmation: "",
        inviteConfirmationMessage: "",
        signupButtons: true,
        accountPending: false,
        overlayContact: false,
        userProfile: {},
        userRef: {},
        userSecret: {}
    }),
    methods: {
        // Login
        login_dialog() {
            this.dialog_login = true
            this.home = true
            this.overlay = false
        },
        async hacer_login() {
            this.public_profile = false
            this.logginin = true
            this.userProfile = {}
            this.userRef = {}
            this.userSecret = {}
            try {
                var response = await axios.post(this.apiUrl + "/api/users/login", {
                    username: this.username,
                    password: this.password
                })
                switch (response.data.user.data.status) {
                    case ("pending"):
                        console.log("pending")
                        localStorage.setItem("userRef", response.data.user.ref["@ref"].id)
                        this.logginin = false
                        this.dialog_login = false
                        this.accountPending = true
                        break
                    case ("active"):
                        localStorage.setItem("userRef", response.data.user.ref["@ref"].id)
                        this.logginin = false
                        this.dialog_login = false
                        this.home = false
                        this.backend = true
                        localStorage.setItem("loggedin", true)
                        break
                }
            } catch (error) {
                console.error(error);
            }
        },
        async recover_password() {
            this.logginin = true
            try {
                var response = await axios.post(this.apiUrl + "/api/users/recover", {
                    username: this.username
                })
                switch (response.data.status) {
                    case ("ok"):
                        this.dialog_login = false
                        this.mail_sent_dialog = true
                        break
                }
            } catch (error) {
                console.error(error);
            }
        },
        cancelar_login() {
            this.dialog_recover_password = false
            this.accountPending = false
            this.dialog_login = false
            this.logginin = false
        },
        login_signup() {
            this.dialog_recover_password = false
            this.confirmationMessage = ""
            this.loginErrorMessage = ""
            this.signupButtons = true
            this.confirmMessage = ""
            this.dialog_signup = true
        },
        recover_password_dialog() {
            this.dialog_recover_password = true
            this.dialog_login = false
        },
        async hacer_signup() {
            if (this.username != this.usernameConfirm) {
                this.errorUsername = "¡El e-mail no coincide!"
            }
            if (this.password != this.passwordConfirm) {
                this.errorUsername = "¡El password no coincide!"
            }
            if (this.username == this.usernameConfirm & this.password == this.passwordConfirm) {
                this.errorUsername = "Creando cuenta..."
                this.signingup = true
                try {
                    const response = await axios.post(this.apiUrl + "/api/users/signup", {
                        username: this.username,
                        password: this.password
                    })
                    if (response.data.status == "error") {
                        this.errorUsername = "Este correo ya está registrado"
                        this.signingup = false
                    }
                    if (response.data.status == "ok") {
                        this.signingup = false
                        this.signupButtons = false
                        this.errorUsername = ""
                        this.confirmationMessage = "¡Tu cuenta se ha creado! \n Haz click en el correo que te acabamos de enviar para confirmar tu cuenta"
                        this.loginErrorMessage = ""
                    } else if (response.data.status.message == "authentication failed") {
                        this.loginErrorMessage = "No se encontró el usuario. Revisa tus datos y vuelve a intentarlo"
                        this.logginin = false
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        },
        cancelar_signup() {
            this.dialog_signup = false
        },
        // Logout
        confirmar_logout() {
            this.dialog_logout = true
        },
        cancelar_logout() {
            this.dialog_logout = false
        },
        hacer_logout() {
            localStorage.removeItem('loggedin')
            localStorage.removeItem('profile')
            localStorage.removeItem('secret')
            localStorage.removeItem('profileRef')
            localStorage.removeItem('userRef')
            this.backend = false
            this.home = true
            this.dialog_logout = false
            this.$store.state.userProfile = {}
        },
        //No confirmar cuenta
        cancel_account() {
            this.home = true
            this.overlay = false
        },
        // Confirmar la cuenta
        async confirm_account() {
            try {
                const response = await axios.post(this.apiUrl + "/api/users/confirm_account", {
                    confirmationCode: this.confirmationCode
                })
                if (response.data.message == "No code") {
                    this.confirmMessage = "Esta cuenta ya ha sido confirmada"
                    this.confirmed = true
                }
                if (response.data.message == "invalid") {
                    this.confirmMessage = "Este código de confirmación no es válido"
                    this.confirmed = true
                }
                if (response.data.message == "ok") {
                    this.confirmMessage = "¡Tu cuenta ha sido confirmada!"
                    this.confirmed = true
                }
            } catch (error) {
                return error
            }
        },
        // Confirmar la cuenta
        async confirm_contact() {
            try {
                const response = await axios.post(this.apiUrl + "/api/users/confirm_contact", {
                    inviteConfirmation: this.inviteConfirmation
                })
                if (response.data.message == "ok") {
                    this.overlayContact = true
                }
            } catch (error) {
                return error
            }
        },
    },
    async created() {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString);
        const qr = urlParams.get('qr')
        const sessionId = urlParams.get('session_id')
        const inviteConfirmation = urlParams.get('inviteConfirmation')
        this.inviteConfirmation = inviteConfirmation
        const confirmationCode = urlParams.get('confirmationCode')
        this.confirmationCode = confirmationCode
        if (qr != null) { // Si encuentra un código QR en la url muestra el perfil público relacionado al QR -----------------------
            localStorage.removeItem("userRef")
            localStorage.removeItem("loggedin")
            this.public_profile = true
            //this.home=false
            //this.backend=false
        } else if (sessionId != null) {
            console.log(sessionId)
        } else if (confirmationCode != null) { // si hay un código de confirmación -----------------------------------------
            this.overlay = true
            //this.home=false
            this.$store.state.activeToken = false
        } else if (inviteConfirmation != null) {
            this.home = true
            await this.confirm_contact()
        }
        if (localStorage.getItem('loggedin')) { // si no hay QR y ha hecho login muestra el backend y oculta el home ---------------------------------
            this.backend = true
            this.public_profile = false
            //this.home=false
            this.$store.state.activeToken = false
        } else { // o muestra el home ---------------------------------------------------------------------------------
            this.home = true
            this.$store.state.activeToken = false
        }
    },
    computed: {},
    mounted() {}
}
</script>

<style scoped>
#container {
    width: 100px;
    height: 100px;
    position: relative;
}

#infoi {
    width: 50%;
    height: 120%;
    position: absolute;
    top: 100px;
    left: 0;
    z-index: 10;
}

.wrap-text {
    white-space: normal;
}

.list {
    color: rgb(1, 9, 9);
    border-color: rgba(192, 0, 250, 0.986);
}

.btn-toggle {
    flex-direction: column;
}
.title h4 {
    background: #ffffff;
    margin: 0 -15px;
    padding: .7em 0;
    text-align: center;
    border-radius: 7px 7px 0 0 ; 
}
.respuesta{
    line-height:1.5em;
    font-weight:normal;
    margin: 0;
    padding: 0;
    background: #ffffff;
    font-size: .65em;
    text-align: left;
}
.respuesta_tab{
    line-height:1.5em;
    font-weight:bold;
    margin: 0;
    padding: 0;
    padding-left: 10px;
    background: #ffffff;
    font-size: .65em;
    text-align: left;
}
.texto_menu{
    color:#2f2f2f;
    font-weight: bold;
}
</style>
