<template>
<v-container app>
    <v-row no-gutters justify="space-around">
        <v-col align="center" cols="12" md="4">
            <!--Tarjeta del usuario -->
            <v-card>
                <v-divider class="mx-3"></v-divider>
                <v-card-text>
                    <v-row>
                        <!--Avatar -->
                        <v-col cols="4">
                            <v-avatar size="100">
                                <v-img :src="profileImage"></v-img>
                            </v-avatar>
                            <v-btn elevation="3" left color="grey" fab dark bottom absolute x-small class="ml-0 mb-7" @click="abrir_editar_foto_perfil()">
                                <v-icon small>mdi-pencil</v-icon>
                            </v-btn>
                        </v-col>
                        <!--Nombre y suscripción -->
                        <v-col align="start" cols="8">
                            <v-list-item class="mt-5">
                                <v-list-item-content>
                                    <v-list-item-title class="text-h7">
                                        {{userProfile.name}} {{userProfile.lastName}}
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="list">{{userProfile.stripe_subscription.type}}<span v-if="userProfile.stripe_subscription.subscription_active"> - Activa</span><span v-if="!userProfile.stripe_subscription.subscription_active"> - Inactiva</span></v-list-item-subtitle>
                                    <v-btn small @click="confirmar_logout()" color="red darken-1">
                                        <v-icon small>mdi-alert</v-icon>
                                        <span class="mr-1 ml-1" color="white">¡SOS!</span>
                                    </v-btn>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <!--Perfil del usuario -->
            <v-expansion-panels align="left" v-if="bottomMenuValue=='perfil'">
                <!--Datos personales -->
                <v-expansion-panel dense>
                    <v-expansion-panel-header>
                        <v-card elevation="0">
                            <v-icon class="pr-3 pb-1" color="black">mdi-account-box</v-icon>Datos personales
                        </v-card>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-card-subtitle primary-title>
                            Proporciona tus datos para que podamos contactarte y hacer la entrega de tu GUARDA QR
                        </v-card-subtitle>
                        <v-list class="pt-0">
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="indigo" class="pt-2">
                                        mdi-account
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-subtitle class="wrap-text">Nombre</v-list-item-subtitle>
                                    <v-list-item-title class="wrap-text">{{userProfile.name}} {{userProfile.lastName}}</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-icon>
                                    <v-btn icon @click="abrir_editar_nombre()">
                                        <v-icon small>mdi-pencil</v-icon>
                                    </v-btn>
                                </v-list-item-icon>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="indigo" class="pt-2">
                                        mdi-cake-variant
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-subtitle class="wrap-text">Fecha de nacimiento</v-list-item-subtitle>
                                    <v-list-item-title class="wrap-text">{{userProfile.birthdate}}</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-icon>
                                    <v-btn icon @click="abrir_editar_cumpleanos()">
                                        <v-icon small>mdi-pencil</v-icon>
                                    </v-btn>
                                </v-list-item-icon>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="indigo" class="pt-2">
                                        mdi-heart
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-subtitle class="wrap-text">Tipo sanguíneo</v-list-item-subtitle>
                                    <v-list-item-title class="wrap-text">{{userProfile.bloodType}}</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-icon>
                                    <v-btn icon @click="abrir_editar_sangre()">
                                        <v-icon small>mdi-pencil</v-icon>
                                    </v-btn>
                                </v-list-item-icon>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="indigo" class="pt-2">
                                        mdi-email
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-subtitle class="wrap-text">email</v-list-item-subtitle>
                                    <v-list-item-title class="wrap-text">{{userProfile.email}}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="indigo" class="pt-2">
                                        mdi-map-marker
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-subtitle>Dirección</v-list-item-subtitle>
                                    <v-list-item-title class="wrap-text">{{userProfile.address.street}}, {{userProfile.address.streetNumber}} <span v-if="userProfile.address.houseNumber!=''">interior - {{userProfile.address.houseNumber}}</span> </v-list-item-title>
                                    <v-list-item-subtitle class="wrap-text">{{userProfile.address.neighborhood}} - CP {{userProfile.address.zipcode}}<br>{{userProfile.address.alcaldiaMunicipio}} <br>{{userProfile.address.state}}, {{userProfile.address.country}} </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-icon>
                                    <v-btn icon @click="abrir_editar_direccion()">
                                        <v-icon small>mdi-pencil</v-icon>
                                    </v-btn>
                                </v-list-item-icon>
                            </v-list-item>
                        </v-list>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <!-- Códigos QR -->
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <v-card elevation="0">
                            <v-icon class="pr-3" color="blue-grey">mdi-qrcode</v-icon> GUARDA QR
                        </v-card>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content v-if="userProfile.stripe_subscription.subscription_active">Administra tu GUARDA QR. Suspende, activalo o pide una reposición.<br>
                        <v-btn text small @click="comming_soon()" color="success" class="pa-4">
                            <v-icon small>mdi-cart-arrow-down</v-icon> Pedir reposición
                        </v-btn>
                        <v-list>
                            <v-list-item v-for="(item, index) in userProfile.qrCodes" v-bind:key=index>
                                <v-list-item-content>
                                    <v-list-item-subtitle>{{index+1}}.- ID: {{item.id}}</v-list-item-subtitle>
                                    <v-list-item-subtitle>Tipo: {{item.type}}</v-list-item-subtitle>
                                    <v-list-item-subtitle>Creado: {{item.dateCreated | tsToDate}}</v-list-item-subtitle>
                                    <v-list-item-subtitle v-if="item.status=='active'">
                                        <v-icon color="green" small>mdi-checkbox-marked-circle</v-icon> Activo desde: {{item.dateModified | tsToDate}}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle v-if="item.status=='inactive'">
                                        <v-icon color="red" small>mdi-minus-circle</v-icon> Inactivo desde: {{item.dateModified | tsToDate}}
                                    </v-list-item-subtitle>
                                    <v-list-item-content>
                                    </v-list-item-content>
                                </v-list-item-content>
                                <v-list-item-icon>
                                    <v-btn icon @click="abrir_editar_qr(index)">
                                        <v-icon small>mdi-pencil {{index}}</v-icon>
                                    </v-btn>
                                </v-list-item-icon>
                            </v-list-item>
                        </v-list>
                    </v-expansion-panel-content>
                    <v-expansion-panel-content v-if="!userProfile.stripe_subscription.subscription_active">Necesitas una suscripción para obtener tus códigos QR<br>
                        <Stripe />
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <!-- Contactos de emergencia -->
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <v-card elevation="0">
                            <v-icon class="pr-3" color="blue-grey">mdi-account-supervisor-circle</v-icon>Contactos de emergencia
                        </v-card>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content> La información de los contactos es privada, no será visible en tu perfil público.<br>
                        <v-col class="text-right">
                            <v-btn text small color="green" @click="abrir_nuevo_contacto()">
                                <v-icon>mdi-plus</v-icon> Agregar
                            </v-btn>
                        </v-col>
                        <v-list>
                            <v-list-item v-for="(item, index) in userProfile.emergencyContacts" v-bind:key=index>
                                <v-list-item-content>
                                    <v-list-item-title>{{index+1}}.- {{item.name}}</v-list-item-title>
                                    <v-list-item-subtitle>{{item.email}}</v-list-item-subtitle>
                                    <v-list-item-subtitle>{{item.countryCode}}{{item.phone}}</v-list-item-subtitle>
                                    <v-list-item-subtitle>Estatus: <span v-if="item.status=='invited'">Invitación sin respuesta</span><span v-if="item.status=='accepted'">Activo</span></v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        <v-icon color="green" v-if="item.emergencyNotifications" small>mdi-bell-ring</v-icon>
                                        <v-icon color="red" v-if="!item.emergencyNotifications" small>mdi-bell-off</v-icon> Emergencias
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        <v-icon color="green" v-if="item.qrLogs" small>mdi-bell-ring</v-icon>
                                        <v-icon color="red" v-if="!item.qrLogs" small>mdi-bell-off</v-icon> Lectura del QR
                                    </v-list-item-subtitle>
                                    <v-list-item-content>
                                    </v-list-item-content>
                                </v-list-item-content>
                                <v-list-item-icon>
                                    <v-btn icon @click="abrir_editar_contacto(index)">
                                        <v-icon small>mdi-pencil {{index}}</v-icon>
                                    </v-btn>
                                    <v-btn icon @click="borrar_contacto(index)">
                                        <v-icon small>mdi-delete {{index}}</v-icon>
                                    </v-btn>
                                </v-list-item-icon>
                            </v-list-item>
                        </v-list>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <!-- Condiciones médicas -->
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <v-card elevation="0">
                            <v-icon class="pr-3" color="blue">mdi-medical-bag</v-icon>Condiciones médicas
                        </v-card>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        Ingresa las condiciones médicas que deban considerarse antes de atenderte en caso de emergencia, como diabetes, cardiopatías, cáncer, etc.<br>
                        <v-col class="text-right">
                            <v-btn text small color="green" @click="abrir_nueva_condicion()">
                                <v-icon>mdi-plus</v-icon> Agregar
                            </v-btn>
                        </v-col>
                        <v-list>
                            <v-list-item v-for="(item, index) in userProfile.medicalConditions" v-bind:key=index>
                                <v-list-item-content>
                                    <v-list-item-title>{{index+1}}.- {{item[0]}}</v-list-item-title>
                                    <v-list-item-subtitle class="wrap-text">{{item[1]}}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-icon>
                                    <v-btn icon @click="abrir_editar_condiciones(index)">
                                        <v-icon small>mdi-pencil {{index}}</v-icon>
                                    </v-btn>
                                    <v-btn icon @click="borrar_condicion_medica(index)">
                                        <v-icon small>mdi-delete {{index}}</v-icon>
                                    </v-btn>
                                </v-list-item-icon>
                            </v-list-item>
                        </v-list>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <!-- Medicamentos -->
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <v-card elevation="0">
                            <v-icon class="pr-3" color="teal">mdi-pill</v-icon>Medicamentos
                        </v-card>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        Ingresa los medicamentos que usas: anticouagulantes, insulina, diuréticos, beta bloqueadores, etc.<br>
                        <v-col class="text-right">
                            <v-btn text small color="green" @click="abrir_nuevo_medicamento()">
                                <v-icon>mdi-plus</v-icon> Agregar
                            </v-btn>
                        </v-col>
                        <v-list>
                            <v-list-item v-for="(item, index) in userProfile.medicines" v-bind:key=index>
                                <v-list-item-content>
                                    <v-list-item-title>{{index+1}}.- {{item[0]}}</v-list-item-title>
                                    <v-list-item-subtitle class="wrap-text">{{item[1]}}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-icon>
                                    <v-btn icon @click="abrir_editar_medicamento(index)">
                                        <v-icon small>mdi-pencil {{index}}</v-icon>
                                    </v-btn>
                                    <v-btn icon @click="borrar_medicamento(index)">
                                        <v-icon small>mdi-delete {{index}}</v-icon>
                                    </v-btn>
                                </v-list-item-icon>
                            </v-list-item>
                        </v-list>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <!-- Alergias -->
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <v-card elevation="0">
                            <v-icon class="pr-3" color="red">mdi-allergy</v-icon>Alergias
                        </v-card>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        Ingresa las alergias que tengas que deban ser consideradas en caso de emergencia, como: penicilina o anestesia.
                        <v-col class="text-right">
                            <v-btn text small color="green" @click="abrir_nueva_alergia()">
                                <v-icon>mdi-plus</v-icon> Agregar
                            </v-btn>
                        </v-col>
                        <v-list>
                            <v-list-item v-for="(item, index) in userProfile.alergies" v-bind:key=index>
                                <v-list-item-content>
                                    <v-list-item-title>{{index+1}}.- {{item[0]}}</v-list-item-title>
                                    <v-list-item-subtitle class="wrap-text">{{item[1]}}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-icon>
                                    <v-btn icon @click="abrir_editar_alergia(index)">
                                        <v-icon small>mdi-pencil {{index}}</v-icon>
                                    </v-btn>
                                    <v-btn icon @click="borrar_alergia(index)">
                                        <v-icon small>mdi-delete {{index}}</v-icon>
                                    </v-btn>
                                </v-list-item-icon>
                            </v-list-item>
                        </v-list>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <!-- Enfermedades y cirugías -->
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <v-card elevation="0">
                            <v-icon class="pr-3" color="deep-orange">mdi-hospital-box-outline</v-icon>Enfermedades y cirugías
                        </v-card>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        Historial de enfermedades o cirugías que se deban tomar en cosideración ante una emergencia.
                        <v-col class="text-right">
                            <v-btn text small color="green" @click="abrir_nueva_enfermedad_cirugia()">
                                <v-icon>mdi-plus</v-icon> Agregar
                            </v-btn>
                        </v-col>
                        <v-list>
                            <v-list-item v-for="(item, index) in userProfile.diseasesSurgeries" v-bind:key=index>
                                <v-list-item-content>
                                    <v-list-item-title class="wrap-text">{{index+1}}.- {{item[0]}}</v-list-item-title>
                                    <v-list-item-subtitle class="wrap-text">{{item[1]}}</v-list-item-subtitle>
                                    <v-list-item-subtitle class="wrap-text">{{item[2]}}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-icon>
                                    <v-btn icon @click="abrir_editar_enfermedad_cirugia(index)">
                                        <v-icon small>mdi-pencil {{index}}</v-icon>
                                    </v-btn>
                                    <v-btn icon @click="borrar_enfermedad_cirugia(index)">
                                        <v-icon small>mdi-delete {{index}}</v-icon>
                                    </v-btn>
                                </v-list-item-icon>
                            </v-list-item>
                        </v-list>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-col>
    </v-row>
    
    <!-- Forma dialog_nombre -->
    <v-dialog v-model="dialog_nombre" max-width="600">
        <v-card>
            <v-card-title>Editar nombre</v-card-title>
            <v-card-text>
                <v-text-field v-model="tempNombre" label="Nombre"></v-text-field>
                <v-text-field v-model="tempApellido" label="Apellidos"></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="red" @click="cancelar_nombre()">Cancelar</v-btn>
                <v-btn text color="green" @click="guardar_nombre()">Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- Forma dialog_foto_perfil -->
    <v-dialog v-model="dialog_foto_perfil" max-width="600">
        <v-card>
            <v-card-title>Foto de perfil</v-card-title>
            <v-card-text>
                <v-file-input v-model="archivoFotoDePerfil" class="ml-5" label="Foto de perfil" prepend-icon="mdi-camera"></v-file-input>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="red" @click="cancelar_fotoDePerfil()">Cancelar</v-btn>
                <v-btn text color="green" :loading="loadingUpdateProfile" @click="guardar_fotoDePerfil()">Guardar</v-btn>
            </v-card-actions>
            <v-card-text align="right" color="red">
                {{errorDeFoto}}
            </v-card-text>
        </v-card>
    </v-dialog>
    <!-- Forma dialog_nacimiento -->
    <v-dialog v-model="dialog_nacimiento" max-width="600">
        <v-card>
            <v-card-title>Editar cumpleaños</v-card-title>

            <v-date-picker v-model="tempCumpleanos"></v-date-picker>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="red" @click="cancelar_cumpleanos()">Cancelar</v-btn>
                <v-btn text color="green" @click="guardar_cumpleanos()">Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- Forma dialog_sangre -->
    <v-dialog v-model="dialog_sangre" max-width="600">
        <v-card>
            <v-card-title>Editar tipo de sangre</v-card-title>
            <v-select class="pa-4" dense v-model="tempSangre" :items="tiposDeSangre" label="Tipo de sangre"></v-select>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="red" @click="cancelar_sangre()">Cancelar</v-btn>
                <v-btn text color="green" @click="guardar_sangre()">Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- Forma dialog_email -->
    <v-dialog v-model="dialog_email" max-width="600">
        <v-card>
            <v-card-title>Editar email</v-card-title>
            <v-card-text>
                <v-text-field v-model="tempEmail" :rules="emailRules" label="e-mail"></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="red" @click="cancelar_email()">Cancelar</v-btn>
                <v-btn text color="green" @click="guardar_email()">Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- Forma dialog_direccion -->
    <v-dialog v-model="dialog_direccion" max-width="600">
        <v-card>
            <v-card-title>Editar direccion</v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-6">

                <v-text-field v-model="tempCalle" label="Calle"></v-text-field>
                <v-text-field v-model="tempNumeroExterior" label="Número exterior"></v-text-field>
                <v-text-field v-model="tempNumeroInterior" label="Número interior"></v-text-field>
                <v-text-field v-model="tempColonia" label="Colonia"></v-text-field>
                <v-text-field v-model="tempAlcOMpo" label="Alcaldía o municipio"></v-text-field>
                <v-text-field v-model="tempEstado" label="Estado"></v-text-field>
                <v-text-field v-model="tempCP" label="Código Postal"></v-text-field>
                <v-text-field v-model="tempPais" label="Pais"></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="red" @click="cancelar_direccion()">Cancelar</v-btn>
                <v-btn text color="green" @click="guardar_direccion()">Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- Forma dialog_editar_condicion -->
    <v-dialog v-model="dialog_condiciones" max-width="600">
        <v-card>
            <v-card-title>Editar condición médica</v-card-title>
            <v-card-text>
                <v-text-field v-model="tempCondiciones" label="Condición Médica"></v-text-field>
                <v-text-field v-model="tempInfoAdicionalCondiciones" label="Información adicional"></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="red" @click="cancelar_condiciones()">Cancelar</v-btn>
                <v-btn text color="green" @click="guardar_condiciones()">Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- Forma dialog_nueva_condicion -->
    <v-dialog v-model="dialog_nueva_condicion" max-width="600">
        <v-card>
            <v-card-title>Agregar condición médica</v-card-title>
            <v-card-text>
                <v-text-field v-model="tempCondiciones" label="Condición Médica"></v-text-field>
                <v-text-field v-model="tempInfoAdicionalCondiciones" label="Información adicional"></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="red" @click="cancelar_nueva_condicion()">Cancelar</v-btn>
                <v-btn text color="green" @click="guardar_nueva_condicion()">Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- Forma dialog_nuevo_medicamento -->
    <v-dialog v-model="dialog_nuevo_medicamento" max-width="600">
        <v-card>
            <v-card-title>Agregar meciamento</v-card-title>
            <v-card-text>
                <v-text-field v-model="tempMedicamento" label="Nombre del medicamento"></v-text-field>
                <v-text-field v-model="tempInfoAdicionalMedicamento" label="Información adicional"></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="red" @click="cancelar_nuevo_medicamento()">Cancelar</v-btn>
                <v-btn text color="green" @click="guardar_nuevo_medicamento()">Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- Forma dialog_editar_medicamento -->
    <v-dialog v-model="dialog_editar_medicamento" max-width="600">
        <v-card>
            <v-card-title>Editar medicamento</v-card-title>
            <v-card-text>
                <v-text-field v-model="tempMedicamento" label="Nombre del medicamento"></v-text-field>
                <v-text-field v-model="tempInfoAdicionalMedicamento" label="Información adicional"></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="red" @click="cancelar_medicamento()">Cancelar</v-btn>
                <v-btn text color="green" @click="guardar_medicamento()">Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- Forma dialog_nueva_alergia -->
    <v-dialog v-model="dialog_nueva_alergia" max-width="600">
        <v-card>
            <v-card-title>Agregar alergia</v-card-title>
            <v-card-text>
                <v-text-field v-model="tempAlergia" label="Nombre de la alergia"></v-text-field>
                <v-text-field v-model="tempInfoAdicionalAlergia" label="Información adicional"></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="red" @click="cancelar_nueva_alergia()">Cancelar</v-btn>
                <v-btn text color="green" @click="guardar_nueva_alergia()">Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- Forma dialog_editar_alergia -->
    <v-dialog v-model="dialog_editar_alergia" max-width="600">
        <v-card>
            <v-card-title>Editar alergia:</v-card-title>
            <v-card-text>
                <v-text-field v-model="tempAlergia" label="Nombre de la alergia"></v-text-field>
                <v-text-field v-model="tempInfoAdicionalAlergia" label="Información adicional"></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="red" @click="cancelar_alergia()">Cancelar</v-btn>
                <v-btn text color="green" @click="guardar_alergia()">Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- Forma dialog_editar_alergia -->
    <v-dialog v-model="dialog_editar_alergia" max-width="600">
        <v-card>
            <v-card-title>Editar alergia</v-card-title>
            <v-card-text>
                <v-text-field v-model="tempAlergia" label="Nombre de la alergia" hint="Penicilina, cacahuates, etc..." persistent-hint></v-text-field>
                <v-text-field v-model="tempInfoAdicionalAlergia" label="Información adicional" hint="Ej. Llevo conmigo un autoinyector de epinefrina ..." persistent-hint></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="red" @click="cancelar_alergia()">Cancelar</v-btn>
                <v-btn text color="green" @click="guardar_alergia()">Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- Forma dialog_nueva_enfermedad_cirugia -->
    <v-dialog v-model="dialog_nueva_enfermedad_cirugia" max-width="600">
        <v-card>
            <v-card-title class="wrap-text">
                <v-icon class="pr-3" color="grey">mdi-hospital-box-outline</v-icon>Agregar enfermedad <br>o cirugía
            </v-card-title>
            <v-card-text>
                <v-text-field class="wrap-text" v-model="tempEnfermedadCirugia" label="Nombre de la enfermedad o cirugía"></v-text-field>
                <v-layout row wrap>
                    <v-menu v-model="fromDateMenu" :close-on-content-click="false" :nudge-right="20" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field class="ml-3" label="Fecha" readonly v-model="tempFechaEnfermedadCirugia" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker locale="mx-es" v-model="tempFechaEnfermedadCirugia" no-title @input="fromDateMenu = false"></v-date-picker>
                    </v-menu>
                </v-layout>
                <v-text-field v-model="tempInfoAdicionalEnfermedadCirugia" label="Información adicional"></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="red" @click="cancelar_nueva_enfermedad_cirugia()">Cancelar</v-btn>
                <v-btn text color="green" @click="guardar_nueva_enfermedad_cirugia()">Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- Forma dialog_editar_enfermedad_cirugia -->
    <v-dialog v-model="dialog_editar_enfermedad_cirugia" max-width="600">
        <v-card>
            <v-card-title class="wrap-text">
                <v-icon class="pr-3" color="grey">mdi-hospital-box-outline</v-icon>Agregar enfermedad <br>o cirugía
            </v-card-title>
            <v-card-text>
                <v-text-field class="wrap-text" v-model="tempEnfermedadCirugia" label="Nombre de la enfermedad o cirugía"></v-text-field>
                <v-layout row wrap>
                    <v-menu v-model="fromDateMenu" :close-on-content-click="false" :nudge-right="20" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field class="ml-3" label="Fecha" readonly v-on="on"></v-text-field>
                        </template>
                        <v-date-picker locale="mx-es" v-model="tempFechaEnfermedadCirugia" no-title @input="fromDateMenu = false"></v-date-picker>
                    </v-menu>
                </v-layout>
                <v-text-field v-model="tempInfoAdicionalEnfermedadCirugia" label="Información adicional"></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="red" @click="cancelar_enfermedad_cirugia()">Cancelar</v-btn>
                <v-btn text color="green" @click="guardar_enfermedad_cirugia()">Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- Forma dialog_nuevo_contacto -->
    <v-dialog v-model="dialog_nuevo_contacto" max-width="600">
        <v-card>
            <v-card-title class="wrap-text">
                <v-icon class="pr-3" color="grey">mdi-account-supervisor-circle</v-icon>Agregar contacto
            </v-card-title>
            <v-card-text>
                <v-text-field class="wrap-text" v-model="tempNombreContacto" label="Nombre del contacto"></v-text-field>
                <v-text-field class="wrap-text" v-model="tempEmailContacto" label="e-mail del contacto" type="email"></v-text-field>
                Teléfono móvil<v-divider></v-divider>
                <v-row class="pt-0 wrap-text">
                </v-row>
                <v-row>
                    <v-col cols="4">
                        <v-autocomplete :rules="[rules.required]" v-model="tempCountryCode" label="Pais" :items="countryCodes" item-value=code item-text=name></v-autocomplete>
                    </v-col>
                    <v-col cols="8">
                        <v-text-field :rules="[rules.required]" v-model="tempTelContacto" label="Número (10 dígitos)" hint="(10 dígitos)"></v-text-field>
                    </v-col>
                </v-row>
                Notificaciones<v-divider></v-divider>
                <div class=" d-flex">
                    <v-switch v-model="swithcEmergencias" label="Enviar alertas de emergencias" persistent-hint hint="Cuanto está activo este contacto recibirá alertas de emergencia por SMS y Mail"></v-switch>
                    <v-icon class="ml-4">mdi-alert</v-icon>
                </div>
                <div class="d-flex  ">
                    <v-switch v-model="swithcQrLogs" label="Enviar aviso en cada lectura del QR" persistent-hint hint="Cuanto está activo este contacto recibirá alertas cada vez que alguien escanee el código QR"></v-switch>
                    <v-icon class="ml-4">mdi-history</v-icon>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="red" @click="cancelar_nuevo_contacto()">Cancelar</v-btn>
                <v-btn text color="green" @click="guardar_nuevo_contacto()">Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- Forma dialog_editar_contacto -->
    <v-dialog v-model="dialog_editar_contacto" max-width="600">
        <v-card>
            <v-card-title class="wrap-text">
                <v-icon class="pr-3" color="grey">mdi-account-supervisor-circle</v-icon>Editar contacto
            </v-card-title>
            <v-card-text>
                <v-text-field class="wrap-text" v-model="tempNombreContacto" label="Nombre del contacto"></v-text-field>
                <v-text-field class="wrap-text" v-model="tempEmailContacto" label="e-mail del contacto"></v-text-field>
                Teléfono móvil<v-divider></v-divider>
                <v-row class="pa-2 wrap-text">
                </v-row>
                <v-row>
                    <v-col cols="4">
                        <v-autocomplete :rules="[rules.required]" v-model="tempCountryCode" label="Pais" :items="countryCodes" item-value=code item-text=name></v-autocomplete>
                    </v-col>
                    <v-col cols="8">
                        <v-text-field :rules="[rules.required]" v-model="tempTelContacto" label="Teléfono movil del contacto" hint="(10 dígitos)"></v-text-field>
                    </v-col>
                </v-row>
                Notificaciones<v-divider></v-divider>
                <div class=" d-flex">
                    <v-switch v-model="swithcEmergencias" label="Enviar alertas de emergencias" persistent-hint hint="Cuanto está activo este contacto recibirá alertas de emergencia por SMS y Mail"></v-switch>
                    <v-icon class="ml-4">mdi-alert</v-icon>
                </div>
                <div class="d-flex  ">
                    <v-switch v-model="swithcQrLogs" label="Enviar aviso en cada lectura del QR" persistent-hint hint="Cuanto está activo este contacto recibirá alertas cada vez que alguien escanee el código QR"></v-switch>
                    <v-icon class="ml-4">mdi-history</v-icon>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="red" @click="cancelar_contacto()">Cancelar</v-btn>
                <v-btn text color="green" @click="guardar_contacto()">Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- Forma dialog_editar_qr -->
    <v-dialog v-model="dialog_editar_qr" max-width="600">
        <v-card>
            <v-card-title class="wrap-text">
                <v-icon class="pr-3" color="grey">mdi-qrcode</v-icon>Editar QR
            </v-card-title>
            <v-card-text>
                ID: {{tempQr}} Tipo: {{tempQrType}}
                <br>
                <v-divider></v-divider>
                <div class=" d-flex">
                    <v-switch v-model="swithcQrCode" :label=qrMessage persistent-hint hint="Cuanto está activo el QR podrá ser leído y podrá enviar alertas."></v-switch>
                    <v-icon class="ml-4">mdi-alert</v-icon>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="red" @click="cancelar_qr()">Cancelar</v-btn>
                <v-btn text color="green" @click="guardar_qr()">Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import countryPhoneCodes from '../../scripts/countryCodes.json'
import estadosYmunicipios from "../../scripts/estados.json";
//var imageTools = require('../../scripts/heicToJpg.js')
import Stripe from "./Stripe.vue"
import axios from 'axios'
export default {
    name: "Backend",
    components: {
        Stripe,
    },
    data: () => ({
        
        show_products: true,
        municipioId: "",
        estadoId: "",
        estados: estadosYmunicipios.estados,
        municipios: estadosYmunicipios.municipios,
        userProfile: {
            user: "",
            name: "",
            lastName: "",
            email: "",
            rol: "user",
            userPic: "",
            birthdate: "",
            bloodType: "",
            stripe_subscription: {
                type: "basic",
                signup_date: "",
                customer_id: "",
                subscription_id: "",
                begin_date: "",
                end_date: "",
                hosted_invoice_url: "",
                subscription_status: "inactive"
            },
            address: {
                street: "",
                streetNumber: "",
                houseNumber: "",
                neighborhood: "",
                zipcode: "",
                alcaldiaMunicipio: "",
                state: "",
                country: ""
            },
            medicalConditions: [],
            diseasesSurgeries: [],
            medicines: [],
            alergies: [],
            emergencyContacts: [],
            qrCodes: []
        },
        rules: {
            required: (value) => !!value || "Requerido."
        },
        alertText: "",
        alert: false,
        alertType: "error",
        value: true,
        tempCountryCode: "",
        user: {},
        profileRef: "",
        loadingUpdateProfile: false,
        bottomMenuValue: "perfil",
        labelQr: "Inactivo",
        tempQrType: "",
        swithcQrCode: true,
        apiurl: process.env.VUE_APP_ROOT_API,
        errorDeFoto: "",
        fotoDePerfil: "",
        archivoFotoDePerfil: "",
        mostrarPerfil: false,
        dialog_foto_perfil: false,
        swithcQrLogs: true,
        swithcEmergencias: true,
        fromDateMenu: false,
        dialog_editar_qr: false,
        qrNumber: "",
        product_selected:false,
        selected: [],
        dialog_suscribirme: false,
        dialog_nuevo_contacto: "",
        dialog_editar_contacto: "",
        dialog_direccion: false,
        dialog_nombre: false,
        dialog_nacimiento: false,
        dialog_sangre: false,
        dialog_email: false,
        dialog_logout: false,
        imageResized: "",
        dialog_condiciones: false,
        dialog_nueva_condicion: false,
        dialog_nuevo_medicamento: false,
        dialog_editar_medicamento: false,
        dialog_editar_alergia: false,
        dialog_nueva_alergia: false,
        dialog_editar_enfermedad_cirugia: false,
        dialog_nueva_enfermedad_cirugia: false,
        //userProfile: {},
        tempNombreContacto: "",
        tempEmailContacto: "",
        tempTelContacto: "",
        tempCondiciones: "",
        tempInfoAdicionalCondiciones: "",
        condicionNo: "",
        tempNombre: "",
        tempApellido: "",
        tempCumpleanos: "",
        tempEmail: "",
        tempSangre: "",
        tempPais: "",
        tempCalle: "",
        tempNumeroExterior: "",
        tempNumeroInterior: "",
        tempColonia: "",
        tempAlcOMpo: "",
        tempEstado: "",
        tempCP: "",
        tempQr: "",
        countryCodes: countryPhoneCodes.countries,
        tempMedicamento: "",
        tempInfoAdicionalMedicamento: "",
        medicamentoNo: "",
        tempAlergia: "",
        tempInfoAdicionalAlergia: "",
        alergiaNo: "",
        enfermedadNo: "",
        tempEnfermedadCirugia: "",
        tempInfoAdicionalEnfermedadCirugia: "",
        tempFechaEnfermedadCirugia: "",
        contactoNo: "",
        tiposDeSangre: ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"],
        emailRules: [
            v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
        ],
        price_selected:false
    }),
    methods: {
        //Direcciones
        obtenMunicipiosDelEstado: function (id) {
            for (var a = 0; a < this.estados.length; a++) {
                if (this.estados[a].id == id) {
                    this.estadoNombre = this.estados[a].name;
                }
            }
            var result = [];
            for (var i = 0; i < this.municipios.length; i++) {
                if (this.municipios[i].state_id == id) {
                    result.push(this.municipios[i]);
                }
            }
            this.municiposLista = result;
        },
        obtenNombreDelMunicipio: function (id) {
            for (var x = 0; x < this.municipios.length; x++) {
                if (this.municipios[x].inegi_id == id) {
                    this.municipioNombre = this.municipios[x].name;
                }
            }
        },
        //-- Qrs
        abrir_editar_qr(numero) {
            this.tempQr = this.userProfile.qrCodes[numero].id
            if (this.userProfile.qrCodes[numero].status == "active") {
                this.swithcQrCode = true
            } else if (this.userProfile.qrCodes[numero].status == "inactive") {
                this.swithcQrCode = false
            }
            this.userProfile.qrCodes[numero].dateModified = Date.now()
            this.tempQrType = this.userProfile.qrCodes[numero].type
            this.dialog_editar_qr = true
            this.qrNumber = numero
        },
        guardar_qr() {
            let m = ""
            if (this.swithcQrCode) {
                m = "active"
            } else {
                m = "inactive"
            }
            this.userProfile.qrCodes[this.qrNumber].status = m
            let editData = {}
            editData["qrCodes"] = this.userProfile.qrCodes
            this.editProfile(editData)
            this.dialog_editar_qr = false
        },
        cancelar_qr() {
            this.dialog_editar_qr = false
        },
        //-- Contactos
        abrir_editar_contacto(numero) {
            this.tempNombreContacto = this.userProfile.emergencyContacts[numero].name
            this.tempEmailContacto = this.userProfile.emergencyContacts[numero].email
            this.tempTelContacto = this.userProfile.emergencyContacts[numero].phone
            this.tempCountryCode = this.userProfile.emergencyContacts[numero].countryCode
            this.swithcEmergencias = this.userProfile.emergencyContacts[numero].emergencyNotifications
            this.swithcQrLogs = this.userProfile.emergencyContacts[numero].qrLogs
            this.contactoNo = numero
            this.dialog_editar_contacto = true
        },
        guardar_contacto() {
            this.userProfile.emergencyContacts[this.contactoNo].name = this.tempNombreContacto
            this.userProfile.emergencyContacts[this.contactoNo].email = this.tempEmailContacto
            this.userProfile.emergencyContacts[this.contactoNo].phone = this.tempTelContacto
            this.userProfile.emergencyContacts[this.contactoNo].countryCode = this.tempCountryCode
            this.userProfile.emergencyContacts[this.contactoNo].emergencyNotifications = this.swithcEmergencias
            this.userProfile.emergencyContacts[this.contactoNo].qrLogs = this.swithcQrLogs
            let editData = {}
            editData["emergencyContacts"] = this.userProfile.emergencyContacts
            this.editProfile(editData)
            this.dialog_editar_contacto = false
        },
        cancelar_contacto() {
            this.dialog_editar_contacto = false
        },
        abrir_nuevo_contacto() {
            this.tempNombreContacto = ""
            this.tempEmailContacto = ""
            this.tempTelContacto = ""
            this.swithcQrLogs = true
            this.swithcEmergencias = true
            this.dialog_nuevo_contacto = true
        },
        guardar_nuevo_contacto() {
            if (this.tempNombreContacto == "" || this.tempEmailContacto == "" || this.tempTelContacto == "") {
                alert("Completa todos los datos")
            } else {
                let contacto = {
                    name: this.tempNombreContacto,
                    email: this.tempEmailContacto,
                    phone: this.tempTelContacto,
                    countryCode: this.tempCountryCode,
                    qrLogs: this.swithcQrLogs,
                    emergencyNotifications: this.swithcEmergencias,
                    status: "pending"
                }
                this.userProfile.emergencyContacts.push(contacto)
                let editData = {}
                editData["emergencyContacts"] = this.userProfile.emergencyContacts
                editData["username"] = this.userProfile.name + " " + this.userProfile.lastName
                console.log(editData.username)
                this.editProfile(editData)
                this.dialog_nuevo_contacto = false
            }
        },
        cancelar_nuevo_contacto() {
            this.dialog_nuevo_contacto = false
        },
        borrar_contacto(numero) {
            var r = confirm("¿Quieres borrar el contacto?", this.userProfile.emergencyContacts[numero].name, "?");
            if (r == true) {
                this.userProfile.emergencyContacts.splice(numero, 1);
                let editData = {}
                editData["emergencyContacts"] = this.userProfile.emergencyContacts
                this.editProfile(editData)
            }
        },
        //-- Enfermedades y cirugías
        abrir_editar_enfermedad_cirugia(numero) {
            this.tempEnfermedadCirugia = this.userProfile.diseasesSurgeries[numero][0]
            this.tempFechaEnfermedadCirugia = this.userProfile.diseasesSurgeries[numero][1]
            this.tempInfoAdicionalEnfermedadCirugia = this.userProfile.diseasesSurgeries[numero][2]
            this.enfermedadNo = numero
            this.dialog_editar_enfermedad_cirugia = true
        },
        guardar_enfermedad_cirugia() {
            this.userProfile.diseasesSurgeries[this.enfermedadNo][0] = this.tempEnfermedadCirugia
            this.userProfile.diseasesSurgeries[this.enfermedadNo][1] = this.tempFechaEnfermedadCirugia
            this.userProfile.diseasesSurgeries[this.enfermedadNo][2] = this.tempInfoAdicionalEnfermedadCirugia
            let editData = {}
            editData["diseasesSurgeries"] = this.userProfile.diseasesSurgeries
            this.editProfile(editData)
            this.dialog_editar_enfermedad_cirugia = false
        },
        cancelar_enfermedad_cirugia() {
            this.dialog_editar_enfermedad_cirugia = false
        },
        abrir_nueva_enfermedad_cirugia() {
            this.tempEnfermedadCirugia = ""
            this.tempInfoAdicionalEnfermedadCirugia = ""
            this.tempFechaEnfermedadCirugia = ""
            this.dialog_nueva_enfermedad_cirugia = true
        },
        guardar_nueva_enfermedad_cirugia() {
            let userProfile = [this.tempEnfermedadCirugia, this.tempFechaEnfermedadCirugia, this.tempInfoAdicionalEnfermedadCirugia]
            this.userProfile.diseasesSurgeries.push(userProfile)
            let editData = {}
            editData["diseasesSurgeries"] = this.userProfile.diseasesSurgeries
            this.editProfile(editData)
            this.dialog_nueva_enfermedad_cirugia = false
        },
        cancelar_nueva_enfermedad_cirugia() {
            this.dialog_nueva_enfermedad_cirugia = false
        },
        borrar_enfermedad_cirugia(numero) {
            var r = confirm("¿Quieres borrar la enfermedad o cirugía?");
            if (r == true) {
                this.userProfile.diseasesSurgeries.splice(numero, 1);
                let editData = {}
                editData["diseasesSurgeries"] = this.userProfile.diseasesSurgeries
                this.editProfile(editData)
                this.dialog_editar_enfermedad_cirugia = false
            }
        },
        //-- Alergias
        abrir_editar_alergia(numero) {
            this.tempAlergia = this.userProfile.alergies[numero][0]
            this.tempInfoAdicionalAlergia = this.userProfile.alergies[numero][1]
            this.alergiaNo = numero
            this.dialog_editar_alergia = true
        },
        guardar_alergia() {
            this.userProfile.alergies[this.alergiaNo][0] = this.tempAlergia
            this.userProfile.alergies[this.alergiaNo][1] = this.tempInfoAdicionalAlergia
            let editData = {}
            editData["alergies"] = this.userProfile.alergies
            this.editProfile(editData)
            this.dialog_editar_alergia = false
        },
        cancelar_alergia() {
            this.dialog_editar_alergia = false
        },
        abrir_nueva_alergia() {
            this.tempAlergia = ""
            this.tempInfoAdicionalAlergia = ""
            this.dialog_nueva_alergia = true
        },
        guardar_nueva_alergia() {
            let userProfile = [this.tempAlergia, this.tempInfoAdicionalAlergia]
            this.userProfile.alergies.push(userProfile)
            let editData = {}
            editData["alergies"] = this.userProfile.alergies
            this.editProfile(editData)
            this.dialog_nueva_alergia = false
        },
        cancelar_nueva_alergia() {
            this.dialog_nueva_alergia = false
        },
        borrar_alergia(numero) {
            var r = confirm("¿Quieres borrar la alergia?");
            if (r == true) {
                this.userProfile.alergies.splice(numero, 1);
                let editData = {}
                editData["alergies"] = this.userProfile.alergies
                this.editProfile(editData)
            }
        },
        //-- Condiciones médicas
        abrir_editar_condiciones(numero) {
            this.tempCondiciones = this.userProfile.medicalConditions[numero][0]
            this.tempInfoAdicionalCondiciones = this.userProfile.medicalConditions[numero][1]
            this.condicionNo = numero
            this.dialog_condiciones = true
        },
        guardar_condiciones() {
            this.userProfile.medicalConditions[this.condicionNo][0] = this.tempCondiciones
            this.userProfile.medicalConditions[this.condicionNo][1] = this.tempInfoAdicionalCondiciones
            let editData = {}
            editData["medicalConditions"] = this.userProfile.medicalConditions
            this.editProfile(editData)
            this.dialog_condiciones = false
        },
        cancelar_condiciones() {
            this.dialog_condiciones = false
        },
        abrir_nueva_condicion() {
            this.tempCondiciones = ""
            this.tempInfoAdicionalCondiciones = ""
            this.dialog_nueva_condicion = true
        },
        guardar_nueva_condicion() {
            let userProfile = [this.tempCondiciones, this.tempInfoAdicionalCondiciones]
            this.userProfile.medicalConditions.push(userProfile)
            let editData = {}
            editData["medicalConditions"] = this.userProfile.medicalConditions
            this.editProfile(editData)
            this.dialog_nueva_condicion = false
        },
        cancelar_nueva_condicion() {
            this.dialog_nueva_condicion = false
        },
        borrar_condicion_medica(numero) {
            var r = confirm("¿Quieres borrar la condición médica?");
            if (r == true) {
                this.userProfile.medicalConditions.splice(numero, 1);
                let editData = {}
                editData["medicalConditions"] = this.userProfile.medicalConditions
                this.editProfile(editData)
            }
        },
        // Medicamentos
        abrir_editar_medicamento(numero) {
            this.tempMedicamento = this.userProfile.medicines[numero][0]
            this.tempInfoAdicionalMedicamento = this.userProfile.medicines[numero][1]
            this.medicamentoNo = numero
            this.dialog_editar_medicamento = true
        },
        guardar_medicamento() {
            this.userProfile.medicines[this.medicamentoNo][0] = this.tempMedicamento
            this.userProfile.medicines[this.medicamentoNo][1] = this.tempInfoAdicionalMedicamento
            let editData = {}
            editData["medicines"] = this.userProfile.medicines
            this.editProfile(editData)
            this.dialog_editar_medicamento = false
        },
        cancelar_medicamento() {
            this.dialog_editar_medicamento = false
        },
        abrir_nuevo_medicamento() {
            this.tempMedicamento = ""
            this.tempInfoAdicionalMedicamento = ""
            this.dialog_nuevo_medicamento = true
        },
        guardar_nuevo_medicamento() {
            let medicamento = [this.tempMedicamento, this.tempInfoAdicionalMedicamento]
            this.userProfile.medicines.push(medicamento)
            let editData = {}
            editData["medicines"] = this.userProfile.medicines
            this.editProfile(editData)
            this.dialog_nuevo_medicamento = false
        },
        cancelar_nuevo_medicamento() {
            this.dialog_nuevo_medicamento = false
        },
        borrar_medicamento(numero) {
            var r = confirm("¿Quieres borrar el medicamento?");
            if (r == true) {
                this.userProfile.medicines.splice(numero, 1);
                let editData = {}
                editData["medicines"] = this.userProfile.medicines
                this.editProfile(editData)
            }
        },
        // Direccion
        abrir_editar_direccion() {
            this.tempPais = this.userProfile.address.country
            this.tempCalle = this.userProfile.address.street
            this.tempNumeroExterior = this.userProfile.address.streetNumber
            this.tempNumeroInterior = this.userProfile.address.houseNumber
            this.tempColonia = this.userProfile.address.neighborhood
            this.tempAlcOMpo = this.userProfile.address.alcaldiaMunicipio
            this.tempEstado = this.userProfile.address.state
            this.tempCP = this.userProfile.address.zipcode
            this.dialog_direccion = true
        },
        guardar_direccion() {
            this.userProfile.address.country = this.tempPais
            this.userProfile.address.street = this.tempCalle
            this.userProfile.address.streetNumber = this.tempNumeroExterior
            this.userProfile.address.houseNumber = this.tempNumeroInterior
            this.userProfile.address.neighborhood = this.tempColonia
            this.userProfile.address.alcaldiaMunicipio = this.tempAlcOMpo
            this.userProfile.address.state = this.tempEstado
            this.userProfile.address.zipcode = this.tempCP
            let editData = {}
            editData["address"] = this.userProfile.address
            this.editProfile(editData)
            this.dialog_direccion = false
        },
        cancelar_direccion() {
            this.dialog_direccion = false
        },
        // Nombre
        abrir_editar_nombre() {
            this.tempNombre = this.userProfile.name
            this.tempApellido = this.userProfile.lastName
            this.dialog_nombre = true
        },
        guardar_nombre() {
            let editData = {}
            editData["name"] = this.tempNombre
            editData["lastName"] = this.tempApellido
            this.editProfile(editData)
            this.dialog_nombre = false
        },
        cancelar_nombre() {
            this.dialog_nombre = false
        },
        // Email
        abrir_editar_email() {
            this.tempEmail = this.userProfile.email
            this.dialog_email = true
        },
        guardar_email() {
            this.userProfile.email = this.tempEmail
            this.dialog_email = false
        },
        cancelar_email() {
            this.dialog_email = false
        },
        // Cumpleaños
        abrir_editar_cumpleanos() {
            this.tempCumpleanos = this.userProfile.birthdate
            this.dialog_nacimiento = true
        },
        guardar_cumpleanos() {
            this.userProfile.birthdate = this.tempCumpleanos
            let editData = {}
            editData["birthdate"] = this.tempCumpleanos
            this.editProfile(editData)
            this.dialog_nacimiento = false
        },
        cancelar_cumpleanos() {
            this.dialog_nacimiento = false
        },
        // Tipo de sangre
        abrir_editar_sangre() {
            this.tempSangre = this.userProfile.bloodType
            this.dialog_sangre = true
        },
        guardar_sangre() {
            this.userProfile.tipo_de_sangre = this.tempSangre
            let editData = {}
            editData["bloodType"] = this.tempSangre
            this.editProfile(editData)
            this.dialog_sangre = false
        },
        cancelar_sangre() {
            this.dialog_sangre = false
        },
        //--Foto de perfil
        abrir_editar_foto_perfil() {
            this.dialog_foto_perfil = true
        },
        guardar_fotoDePerfil() {
            let editData = {}
            this.loadingUpdateProfile = true
            if (this.archivoFotoDePerfil != "") {
                this.editProfile(editData)
            } else {
                this.errorDeFoto = "Por favor elige una foto"
                this.loadingUpdateProfile = false
            }
        },
        cancelar_fotoDePerfil() {
            this.dialog_foto_perfil = false
            this.loadingUpdateProfile = false
        },
        //enviar datos al backend
        async editProfile(editData) {
            let formData = new FormData();
            //llena la forma
            if (this.archivoFotoDePerfil != "") {
                let fotoDePerfilFileName = localStorage.getItem('profileRef') + "_" + Date.now() + "_" + this.archivoFotoDePerfil.name
                formData.append("profilePreviousFile", this.userProfile.userPic)
                formData.append("files", this.archivoFotoDePerfil, fotoDePerfilFileName)
                editData["userPic"] = fotoDePerfilFileName
            }
            formData.append("editData", JSON.stringify(editData));
            formData.append("profileRef", localStorage.getItem('profileRef'))
            //envía la forma
            try {
                const response = await axios.put(this.apiurl + "/api/perfiles/editprofile", formData)
                if (response) {
                    localStorage.setItem('profile', JSON.stringify(response.data.data));
                    this.userProfile = JSON.parse(localStorage.getItem('profile'))
                    this.loadingUpdateProfile = false
                    this.dialog_foto_perfil = false
                    this.archivoFotoDePerfil = ""
                }

            } catch (err) {
                console.log(err)
            }
        },
        async resize(file, size) {
            if (file.type == "image/heic") {
                console.log("HEIC")
                //imageTools.convertHeicToJpg(this.archivoFotoDePerfil)
            } else {

                const canvas = document.createElement('canvas')
                const ctx = canvas.getContext('2d')

                canvas.width = size
                canvas.height = size

                const bitmap = await createImageBitmap(file)
                const {
                    width,
                    height
                } = bitmap

                const ratio = Math.max(size / width, size / height)

                const x = (size - (width * ratio)) / 2
                const y = (size - (height * ratio)) / 2

                ctx.drawImage(bitmap, 0, 0, width, height, x, y, width * ratio, height * ratio)

                return new Promise(resolve => {
                    canvas.toBlob(blob => {
                        resolve(blob)
                    }, 'image/webp', 1)
                })
            }
        },

    },
    computed: {
        qrMessage() {
            if (this.swithcQrCode) {
                return "Activo"
            } else {
                return "Inactivo"
            }
        },
        profileImage() {
            if (this.userProfile.userPic != "") {
                return "/img/" +
                    this.userProfile.userPic
            } else {
                return "/img/user_icon.png"
            }
        }
    },
    async created() {
        let ref = localStorage.getItem("userRef")
        if (ref) {
            try {
                let profile = await axios.post(this.apiurl + "/api/perfiles/getprofile", {
                    userRef: ref
                })
                if (profile) {
                    localStorage.setItem('profileRef', profile.data.ref['@ref'].id)
                    this.userProfile = profile.data.data
                    localStorage.setItem("profile", JSON.stringify(profile.data.data))
                }
            } catch (err) {
                console.log(err)
            }
            //this.userProfile=JSON.parse(localStorage.getItem('profile'))
            //this.profileRef=localStorage.getItem('profileRef')
            //this.user=localStorage.getItem('user')
        }
    }
}
</script> 

<style>

</style>
