<template>
<v-container app>
<v-row no-gutters justify="space-around">
    <v-col align="center" cols="12" md="4">
        <v-data-table
            dense
            @click:row="handleClick"
            width="600px "
            :headers="headers"
            :items="qrLogs"
            :items-per-page="20"
            class="elevation-1"
            mobile-breakpoint="0"
        ></v-data-table>  
    </v-col>
</v-row>
</v-container>
</template>

<script>
export default {
    name: 'QrLogs',
data: () => ({
    headers: [
        { text: 'Fecha', value: 'fecha' },
        { text: 'QR ID', value: 'id' },
        { text: 'Ubicación', value: 'gps' }
      ],
      qrLogs:[
       
      ]
})
}

</script>

<style>

</style>