<template>
<v-container app>
<v-row no-gutters justify="space-around">
    <v-col cols="12" md="4">
      <v-card>
          <v-layout justify-center align-center>
              <v-flex shrink>
                  <v-avatar size="120" class="ma-2"><v-img :src="profileImage"></v-img></v-avatar>  
              </v-flex>
        </v-layout>
        <v-list two-line>
          <v-list-item>
          <v-row no-gutters justify="space-around">
          <v-col cols="12" md="4">
            <v-list-item-content>
              <v-list-item-title >{{userProfile.name}} {{userProfile.lastName}}</v-list-item-title>
              <v-list-item-subtitle>{{userProfile.email}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-col>
            </v-row>
          </v-list-item>
          <v-divider inset></v-divider>
          <v-list-item v-if="userProfile.stripe_subscription.subscription_active">
            <v-list-item-icon>
              <v-icon color="indigo">
                mdi-account-cash
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="my-title">Suscripción</v-list-item-title>
              <v-list-item-subtitle>{{userProfile.stripe_subscription.type}} <span v-if="userProfile.stripe_subscription.cancel_at_period_end">(Cancelada)</span></v-list-item-subtitle>
              <v-list-item-subtitle>{{userProfile.stripe_subscription.subscription_nickname}}</v-list-item-subtitle>
              <v-list-item-subtitle>{{userProfile.stripe_subscription.subscription_cost/100 | toCurrency}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
    
          <v-list-item v-if="userProfile.stripe_subscription.subscription_active">
          <v-list-item-icon>
              <v-icon color="indigo">
                mdi-calendar-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="my-title">Vigencia </v-list-item-title>
              <v-list-item-subtitle>{{userProfile.stripe_subscription.begin_date *1000 | tsToDate}}</v-list-item-subtitle>
              <v-list-item-subtitle>{{userProfile.stripe_subscription.end_date *1000| tsToDate}}</v-list-item-subtitle>
              <v-list-item-subtitle><v-icon class="pb-1 pr-1" v-if="!userProfile.stripe_subscription.cancel_at_period_end"  color="green">
                mdi-autorenew
              </v-icon><span v-if="!userProfile.stripe_subscription.cancel_at_period_end" >Renovación automática</span><v-icon v-if="userProfile.stripe_subscription.cancel_at_period_end" small color="red">
                mdi-autorenew-off
              </v-icon></v-list-item-subtitle>
              <v-list-item-subtitle>
              Recibo <v-btn  small icon :href="userProfile.stripe_subscription.hosted_invoice_url">
                <v-icon>mdi-download</v-icon>
              </v-btn></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider inset></v-divider>
          <v-list-item >
            <v-list-item-action class="mx-auto">
            <Stripe v-if="!userProfile.stripe_subscription.subscription_active || userProfile.stripe_subscription.cancel_at_period_end" />
              <v-btn text v-if="userProfile.stripe_subscription.subscription_active && !userProfile.stripe_subscription.cancel_at_period_end" @click="openCancelDialog()" small rounded color="red"><v-icon>mdi-cancel</v-icon>Cancelar subscripción</v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>
     <!--Confirmar cancelar subscripción-->
  <v-overlay 
  class="pa-3"
    align="center"
    opacity=.85
    justify="center"
    
    :value="confirmCancel"
    >
    <v-icon large>mdi-emoticon-sad</v-icon>
    <br><br>
    ¿Quieres cancelar tu cuenta?"
    <br><br>
    <v-btn class="mr-2" color=red @click="confirmCancel=false">No</v-btn>
    <v-btn :loading="canceling" class="ml-2" color=green @click="cancel_subscription(userProfile.stripe_subscription.subscription_id)">Sí</v-btn>
  </v-overlay>
       <!--Se canceló con éxito-->
  <v-overlay 
  class="pa-3"
    align="center"
    opacity=.85
    justify="center"
    
    :value="canceled"
    >
    <v-icon large>mdi-closee-circle</v-icon>
    <br><br>
    Tu cuenta ha sido cancelada. Ya no se hará el cárgo automático a tu tarjeta. Puedes seguir usando tu suscripción mientras termina el período vigente."
    <br><br>
    <v-btn class="mr-2" color=green @click="canceled=false">Ok</v-btn>
  </v-overlay>
    </v-col>
</v-row>
</v-container>
</template>

<script>
import Stripe from "./Stripe.vue"
import axios from 'axios'
export default {
    name: 'Account',
    components: {
      Stripe,
  },
data: () => ({
  canceling:false,
    profileRef:"",
    canceled: false,
    confirmCancel:false,
    userProfile:"",
    user:"",
    apiurl:process.env.VUE_APP_ROOT_API,
}),
methods:{
    openCancelDialog(){
      this.canceling=false
      this.confirmCancel=true
    },
    async cancel_subscription(id){
      this.canceling=true
    try {
      var response = await axios.post(this.apiurl + "/api/pay/cancel-subscription", {
          subscription_id: id
          })
          console.log(response.data.cancel_at_period_end)
          if(response.data.cancel_at_period_end){
            this.confirmCancel=false
            this.canceled=true
            let editData={stripe_subscription:{
              cancel_at_period_end:true
            } }
            let r=await this.editProfile(editData)
            console.log(r)
          }
          console.log(response)
    }catch(err){
        console.log(err)
    }  
},
//enviar datos al backend
  async editProfile(editData) {
    let formData = new FormData();
    //llena la forma
    formData.append("editData", JSON.stringify(editData));
    formData.append("secret", localStorage.getItem('secret'));
    formData.append("profileRef", localStorage.getItem('profileRef'))
    //envía la forma
    try{
      const response = await axios.put(this.apiurl + "/api/perfiles/editprofile", formData) 
        if (response) {
          localStorage.setItem('profile', JSON.stringify(response.data.data));
          this.userProfile=JSON.parse(localStorage.getItem('profile'))
        }
        
      }catch(err){
        console.log(err)
      }
    }
},
created(){
    this.user=JSON.parse(localStorage.getItem("user"))
    this.profileRef=localStorage.getItem('profileRef')
    this.userProfile=JSON.parse(localStorage.getItem('profile'))
  },
  computed: {
    qrMessage(){
      if(this.swithcQrCode) {
        return "Activo"}
         else {
          return "Inactivo"}
    },
    profileImage() {
      if (this.userProfile.userPic != "") {
        return "/img/" +
          this.userProfile.userPic
      } else {
        return "/img/user_icon.png"
      }
    }
  },
}
</script>

<style scoped>
.my-title {
  font-size: 0.8em;
} 

</style>